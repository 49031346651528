import avartarLm from '../../assets/images/home/review/ceo-lineman-logo-mobile.png';
import avartarFb from '../../assets/images/home/review/ceo-fb-mobile.png';
import avartarGrab from '../../assets/images/home/review/ceo-grab-food-mobile.png';
import iconFb from '../../assets/images/home/footer/social/icon-fb-footer-mobile.png';
import iconIg from '../../assets/images/home/footer/social/icon-ig-footer-mobile.png';
import iconTk from '../../assets/images/home/footer/social/icon-tk-footer-mobile.png';
import iconLn from '../../assets/images/home/footer/social/icon-ln-footer-mobile.png';
import iconTel from '../../assets/images/contactUs/icon-tel.png';
import iconLine from '../../assets/images/contactUs/icon-line-mb.png';
import rollsRoyceCar from '../../assets/images/home/car-detail/rolls-roy-phantom/rolls-royce-phantom-min.png';
import optionOneRolls from '../../assets/images/home/car-detail/rolls-roy-phantom/options-1-min.jpeg';
import optionTwoRolls from '../../assets/images/home/car-detail/rolls-roy-phantom/options-2-min.jpeg';
import optionThreeRolls from '../../assets/images/home/car-detail/rolls-roy-phantom/options-3-min.jpeg';
import optionFourRolls from '../../assets/images/home/car-detail/rolls-roy-phantom/options-4-min.jpeg';
import alPhardCar from '../../assets/images/home/car-detail/alphard/alphard-min.png';
import optionOneAlPhard from '../../assets/images/home/car-detail/alphard/options-1.jpeg';
import optionTwoAlPhard from '../../assets/images/home/car-detail/alphard/options-2.jpeg';
import optionThreeAlPhard from '../../assets/images/home/car-detail/alphard/options-3.jpeg';
import optionFourAlPhard from '../../assets/images/home/car-detail/alphard/options-4.jpeg';
import optionFiveAlPhard from '../../assets/images/home/car-detail/alphard/options-5.jpeg';
import bentleyCar from '../../assets/images/home/car-detail/bentley-flying/bentley-flying-min.jpeg';
import optionOneBentley from '../../assets/images/home/car-detail/bentley-flying/options-1-min.jpeg';
import optionTwoBentley from '../../assets/images/home/car-detail/bentley-flying/options-2-min.jpeg';
import optionThreeBentley from '../../assets/images/home/car-detail/bentley-flying/options-3-min.jpeg';
import optionFourBentley from '../../assets/images/home/car-detail/bentley-flying/options-4-min.jpeg';
import optionFiveBentley from '../../assets/images/home/car-detail/bentley-flying/options-5-min.jpeg';
import maybachCar from '../../assets/images/home/car-detail/maybach/maybach-min.png';
import optionOneMaybach from '../../assets/images/home/car-detail/maybach/options-1-min.png';
import optionTwoMaybach from '../../assets/images/home/car-detail/maybach/options-2-min.png';
import optionThreeMaybach from '../../assets/images/home/car-detail/maybach/options-3-min.png';
import optionFourMaybach from '../../assets/images/home/car-detail/maybach/options-4-min.png';
import benzseCar from '../../assets/images/home/car-detail/benzse/benzSE-min.jpeg';
import optionOneBenzse from '../../assets/images/home/car-detail/benzse/options-1-min.jpeg';
import optionTwoBenzse from '../../assets/images/home/car-detail/benzse/options-2-min.jpeg';
import optionThreeBenzse from '../../assets/images/home/car-detail/benzse/options-3-min.jpeg';
import optionFourBenzse from '../../assets/images/home/car-detail/benzse/options-4-min.jpeg';
import bentaygaCar from '../../assets/images/home/car-detail/bentayga/bentayga-min.jpeg';
import optionOneBentayga from '../../assets/images/home/car-detail/bentayga/options-1-min.jpeg';
import optionTwoBentayga from '../../assets/images/home/car-detail/bentayga/options-2-min.jpeg';
import optionThreeBentayga from '../../assets/images/home/car-detail/bentayga/options-3-min.jpeg';
import optionFourBentayga from '../../assets/images/home/car-detail/bentayga/options-4-min.jpeg';
import audiCar from '../../assets/images/home/car-detail/audi/audi-min.jpeg';
import optionOneAudi from '../../assets/images/home/car-detail/audi/options-1-min.jpeg';
import optionTwoAudi from '../../assets/images/home/car-detail/audi/options-2-min.jpeg';
import optionThreeAudi from '../../assets/images/home/car-detail/audi/options-3-min.jpeg';
import optionFourAudi from '../../assets/images/home/car-detail/audi/options-4-min.jpeg';

export const titleMenuNavBar = {
    th: [
        {
            id: 1,
            title: 'เกี่ยวกับ',
            ref: 'aboutUs',
        },
        {
            id: 2,
            title: 'ราคาค่าบริการ',
            ref: 'servicesPrice',
        },
        {
            id: 3,
            title: 'แกลลอรี่',
            ref: 'gallery',
        },
        {
            id: 4,
            title: 'ประสบการณ์',
            ref: 'sectionExperience',
        },
        // {
        //     id: 5,
        //     title: 'REVIEW',
        //     ref: 'review'
        // }
    ],
    en: [
        {
            id: 1,
            title: 'ABOUT US',
            ref: 'aboutUs',
        },
        {
            id: 2,
            title: 'SERVICE PRICE',
            ref: 'servicesPrice',
        },
        {
            id: 3,
            title: 'GALLERY',
            ref: 'gallery',
        },
        {
            id: 4,
            title: 'EXPERIENCE',
            ref: 'sectionExperience',
        },
        // {
        //     id: 5,
        //     title: 'REVIEW',
        //     ref: 'review'
        // }
    ],
    cn: [
        {
            id: 1,
            title: '关于我们',
            ref: 'aboutUs',
        },
        {
            id: 2,
            title: '服务费',
            ref: 'servicesPrice',
        },
        {
            id: 3,
            title: '画廊',
            ref: 'gallery',
        },
        {
            id: 4,
            title: '服务',
            ref: 'sectionExperience',
        },
        // {
        //     id: 5,
        //     title: 'REVIEW',
        //     ref: 'review'
        // }
    ],
};

export const titleAboutHome = {
    th: {
        topTitle: 'Luxury29 Car Rental เราคือผู้ให้บริการรถเช่าพร้อมคนขับ',
        midTitle:
            'บริการด้วยรถยนต์ที่มีคุณภาพและดีไซน์ที่สวยงาม พร้อมคนขับที่มีประสบการณ์และมีมาตรฐาน เพื่อให้ลูกค้าได้รับความพึงพอใจในการเดินทาง มาพร้อมกับสิ่งอำนวยความสะดวกภายในรถครบครัน อีกทั้งระบบการแจ้งเตือนและความปลอดภัยที่มาพร้อมกับ GPS Tracking ติดตามความปลอดภัยแบบ 24 ชม. สามารถตรวจสอบตำแหน่งรถแบบเรียลไทม์',
        bottomTitle:
            'รวมทั้งพนักงานขับรถที่ประสบการณ์ความชำนาญอย่างดีเยี่ยมในทุกเส้นทาง นอกจากนี้ยังมีบริการเสริมอื่นๆ เช่น บริการรับ-ส่งสนามบิน การเช่ารถพร้อมคนขับสำหรับงานอีเว้นท์ต่างๆ เช่น งานแต่งงาน งานธุรกิจ หรืองานสัมมนา',
    },
    en: {
        topTitle:
            'Luxury29 Car Rental is a provider of chauffeur-driven car rental services',
        midTitle:
            'We provide high-quality cars with beautiful designs, accompanied by experienced drivers to ensure customer satisfaction. Our cars have comprehensive facilities, safety features like notification systems and 24-hour GPS tracking.',
        bottomTitle: `We offer airport transfers, car rental with drivers for events, and reservations can be made via phone, email, or our application. Enjoy luxurious and worry-free travel with Luxury29 Car Rental's chauffeur-driven car rental service.`,
    },
    cn: {
        topTitle: 'Luxury29 Car Rental 是一家提供司机驾驶的汽车租赁服务提供商',
        midTitle:
            '我们提供设计精美的高品质汽车，并配有经验丰富的驾驶员，以确保客户满意。 我们的汽车拥有完善的设施、通知系统和 24 小时 GPS 跟踪等安全功能。',
        bottomTitle:
            '我们提供机场接送服务、带司机的活动用车租赁服务，并且可以通过电话、电子邮件或我们的应用程序进行预订。 Luxury29 Car Rental专职司机租车服务, 让您享受豪华无忧的旅行。',
    },
};

export const titleServicesPrice = {
    th: {
        titleTop:
            'Luxury29 Car Rental เช่ารถหรูพร้อมคนขับระดับพรีเมียม\nเพื่อประสบการที่พิเศษ',
    },
    en: {
        titleTop:
            'Book a Most Premium Luxury Car\nRental Service With Luxury29 Car Rental',
    },
    cn: {
        titleTop: '通过 Luxury29 Car Rental\n预订最优质的豪华汽车租赁服务',
    },
};

export const titleExperience = {
    th: [
        {
            title: 'รับ-ส่ง สนามบิน',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'airport',
        },
        {
            title: 'จังหวัด',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'city',
        },
        {
            title: 'การเดินทางขององค์กร',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'travel',
        },
        {
            title: 'คอนเสิร์ตและกิจกรรม',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'concert',
        },
        {
            title: 'บริการรับ-ส่ง ที่โรงเรียน',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'school',
        },
        {
            title: 'การขนส่งทางการแพทย์',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'medical',
        },
    ],
    en: [
        {
            title: 'Airport Transportation',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'airport',
        },
        {
            title: 'City&Town',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'city',
        },
        {
            title: 'Corporate Travel',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'travel',
        },
        {
            title: 'Concerts&Events',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'concert',
        },
        {
            title: 'School Pick-Ups',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'school',
        },
        {
            title: 'Medical Transportation',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'medical',
        },
    ],
    cn: [
        {
            title: '机场接送 / 机场接机 (Airport Pick-up)',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'airport',
        },
        {
            title: '城市',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'city',
        },
        {
            title: '商务旅行',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'travel',
        },
        {
            title: '音乐会和活动',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'concert',
        },
        {
            title: '学校接机',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'school',
        },
        {
            title: '医疗运输',
            desc: 'Dress modestly, no smoking, with courteous service',
            tag: 'medical',
        },
    ],
};

export const titleFooter = [
    {
        headTitle: {
            th: 'ข้อมูล',
            en: 'Information',
            cn: '资讯',
        },
        contact: [],
        childTitle: [
            {
                title: {
                    th: 'ข้อกำหนดและเงื่อนไข',
                    en: 'Terms & Condition',
                    cn: '条款和条件',
                },
                link: '/policy/terms-and-condition',
            },
            {
                title: {
                    th: 'นโยบายความเป็นส่วนตัว',
                    en: 'Privacy Policy',
                    cn: '隐私政策',
                },
                link: '/policy/privacy-policy',
            },
            {
                title: {
                    th: 'นโยบายคุกกี้',
                    en: 'Cookie Policy',
                    cn: '小甜饼存储策略',
                },
                link: '/policy/cookie-policy',
            },
            {
                title: {
                    th: 'ติดต่อ',
                    en: 'Contact',
                    cn: '联系我们',
                },
                link: '/contact',
            },
            {
                title: {
                    th: 'ผู้ลงทะเบียน / เข้าสู่ระบบ',
                    en: 'Register / Login',
                    cn: '注册 / 登录',
                },
                link: '/member/register',
            },
        ],
    },
    {
        headTitle: {
            th: 'ศูนย์ช่วยเหลือ',
            en: 'Help Center',
            cn: '帮助',
        },
        contact: [],
        childTitle: [
            {
                title: {
                    th: 'วิธีการจอง',
                    en: 'How to Booking',
                    cn: '如何预订',
                },
                link: '/policy/how-to-booking',
            },
            {
                title: {
                    th: 'วิธีการชำระเงิน',
                    en: 'Payment Methods',
                    cn: '支付方式',
                },
                link: '/policy/payment-methods',
            },
            {
                title: {
                    th: 'การยกเลิก',
                    en: 'Cancellation',
                    cn: '取消政策',
                },
                link: '/policy/cancellation',
            },
            {
                title: {
                    th: 'คำถามที่พบบ่อย',
                    en: 'FAQ',
                    cn: '常问问题',
                },
                link: '/',
            },
        ],
    },
    {
        headTitle: {
            th: 'Hotline',
            en: 'Hotline',
            cn: 'Hotline',
        },
        // subTitle: {
        //     th: 'สถานที่',
        //     en: 'Location',
        //     cn: '地点'
        // },
        // childTitle: [{
        //     location: {
        //         th: 'ที่ตั้ง จัสมิน อินเตอร์เนชั่นแนล 200 หมู่ 4 ถ.แจ้งวัฒนะ ต.ปากเกร็ด อ.ปากเกร็ด จ.นนทบุรี 11120',
        //         en: 'JASMINE INTERNATIONAL 200 MOO 4, Chaeng Wattana Road, Pak kret District, Nonthaburi 11120',
        //         cn: 'JASMINE INTERNATIONAL 200 MOO 4, Chaeng Wattana Road, Pak kret District, Nonthaburi 11120'
        //     },
        //     link: '/'
        // }],
        contact: [
            {
                title: 'Tel: 064 642 4915',
            },
        ],
        social: [
            {
                alt: 'iconFb',
                link: 'https://www.facebook.com/profile.php?id=61550725572913&mibextid=ZbWKwL',
                src: iconFb.src,
            },
            {
                alt: 'iconIg',
                link: 'https://www.instagram.com/_vip29_/?via=webft',
                src: iconIg.src,
            },
            {
                alt: 'iconLn',
                link: 'https://line.me/R/ti/p/@271qmttm?ts=05202119&oat_content=url',
                src: iconLn.src,
            },
            // {
            //         alt: 'iconTwt',
            //         link: '#?via=webft',
            //         src: iconTwt.src,
            //     },
            // {
            //     alt: 'iconTk',
            //     link: 'https://www.tiktok.com/@vip29_carrental?is_from_webapp=1&sender_device=pc',
            //     src: iconTk.src,
            // },
        ],
    },
];

export const titleReview = {
    th: [],
    en: [
        {
            avatar: avartarLm.src,
            name: 'Levante Trofeo',
            position: 'CEO Lineman Thailand',
            detail: `Levante Trofeo Fuoriserie Edition 
        for Massimo Bottura embodies a 
        concept of creativity that the 
        Maserati Fuoriserie customisation 
        program and Massimo Bottura 
        share: the creativity to be open to 
        experience.`,
        },
        {
            avatar: avartarFb.src,
            name: 'Levante Trofeo',
            position: 'CEO Facebook Thailand',
            detail: `Levante Trofeo Fuoriserie Edition 
        for Massimo Bottura embodies a 
        concept of creativity that the 
        Maserati Fuoriserie customisation 
        program and Massimo Bottura 
        share: the creativity to be open to 
        experience.`,
        },
        {
            avatar: avartarGrab.src,
            name: 'Levante Trofeo',
            position: 'CEO Grabfood Thailand',
            detail: `Levante Trofeo Fuoriserie Edition 
        for Massimo Bottura embodies a 
        concept of creativity that the 
        Maserati Fuoriserie customisation 
        program and Massimo Bottura 
        share: the creativity to be open to 
        experience.`,
        },
        {
            avatar: avartarFb.src,
            name: 'Levante Trofeo',
            position: 'CEO Facebook Thailand',
            detail: `Levante Trofeo Fuoriserie Edition 
        for Massimo Bottura embodies a 
        concept of creativity that the 
        Maserati Fuoriserie customisation 
        program and Massimo Bottura 
        share: the creativity to be open to 
        experience.`,
        },
        {
            avatar: avartarLm.src,
            name: 'Levante Trofeo',
            position: 'CEO Lineman Thailand',
            detail: `Levante Trofeo Fuoriserie Edition 
        for Massimo Bottura embodies a 
        concept of creativity that the 
        Maserati Fuoriserie customisation 
        program and Massimo Bottura 
        share: the creativity to be open to 
        experience.`,
        },
        {
            avatar: avartarGrab.src,
            name: 'Levante Trofeo',
            position: 'CEO Grabfood Thailand',
            detail: `Levante Trofeo Fuoriserie Edition 
        for Massimo Bottura embodies a 
        concept of creativity that the 
        Maserati Fuoriserie customisation 
        program and Massimo Bottura 
        share: the creativity to be open to 
        experience.`,
        },
        {
            avatar: avartarFb.src,
            name: 'Levante Trofeo',
            position: 'CEO Facebook Thailand',
            detail: `Levante Trofeo Fuoriserie Edition 
        for Massimo Bottura embodies a 
        concept of creativity that the 
        Maserati Fuoriserie customisation 
        program and Massimo Bottura 
        share: the creativity to be open to 
        experience.`,
        },
        {
            avatar: avartarGrab.src,
            name: 'Levante Trofeo',
            position: 'CEO Grabfood Thailand',
            detail: `Levante Trofeo Fuoriserie Edition 
        for Massimo Bottura embodies a 
        concept of creativity that the 
        Maserati Fuoriserie customisation 
        program and Massimo Bottura 
        share: the creativity to be open to 
        experience.`,
        },
        {
            avatar: avartarLm.src,
            name: 'Levante Trofeo',
            position: 'CEO Lineman Thailand',
            detail: `Levante Trofeo Fuoriserie Edition 
        for Massimo Bottura embodies a 
        concept of creativity that the 
        Maserati Fuoriserie customisation 
        program and Massimo Bottura 
        share: the creativity to be open to 
        experience.`,
        },
    ],
    cn: [],
};

export const titleContactUsHeader = {
    th: {},
    en: {
        addressTitle: 'Address',
        addressDetail: [
            'JASMINE INTERNATIONAL',
            '200 Moo 4, Chaeng Wattana Road,',
            'Pak Kret Subdistrict, Pak Kret District',
            'Nonthaburi Province 11120',
        ],
        contactTitle: 'Contact',
        contactDetail: [
            {
                title: 'Tel: 064 642 4915',
                image: iconTel.src,
                alt: 'Tel',
                link: 'tel:+66646424915',
            },
            {
                title: 'Line: @lux29car',
                image: iconLine.src,
                alt: 'line',
                link: 'https://line.me/ti/p/YURVv?via=webft',
            },
            {
                title: 'Facebook: Luxury29 Car Rantal',
                image: iconFb.src,
                alt: 'fb',
                link: 'https://www.facebook.com/profile.php?id=61550725572913&mibextid=ZbWKwL',
            },
            {
                title: 'Instagram: luxury29_carrental',
                image: iconIg.src,
                alt: 'ig',
                link: 'https://www.instagram.com/_vip29_/',
            },
        ],
        contactForm: 'Contact form',
    },
    cn: {},
};

export const titleTermsOfService = {
    th: [],
    en: [
        {
            no: '01',
            headTitle: 'How to book',
            desc: `Our cancellation has a policy that NO REFUND for your 
        payments However, we can move the dates within 30 
        days. If more days, YOU MUST BOOK IN THE NEW 
        RESERVATION and will apply only once your booking is confirmed`,
        },
        {
            no: '02',
            headTitle: 'Payment Methods',
            desc: `Our cancellation has a policy that NO REFUND for your 
        payments However, we can move the dates within 30 
        days. If more days, YOU MUST BOOK IN THE NEW 
        RESERVATION and will apply only once your booking is confirmed`,
        },
        {
            no: '03',
            headTitle: 'Conditions and Reschedule Methods',
            desc: `Our cancellation has a policy that NO REFUND for your 
        payments However, we can move the dates within 30 
        days. If more days, YOU MUST BOOK IN THE NEW 
        RESERVATION and will apply only once your booking is confirmed. 
        A confirmed booking is when we have advised you that the service 
        request is available and has been reserved. We do not charge a 
        cancellation fee for service requests that have not been confirmed.`,
            descSecond: true,
            descSecondOne: 'To cancel a reservation through',
            descSecondTwo: 'or',
            descSecondThree: 'apply by sending an email',
            descSecondFour: `to and inserting 
        the word 'Cancel' in the subject line. The email must include 
        the following details:`,
            lineID: 'Line ID: @VIP29',
            email: 'vip29@pinvestment.co.th',
            descThird: [
                'Booking reference ID number',
                'Email address you used for the booking',
                'First and family name used for the booking',
                'Booking Date',
            ],
            tableHeader: 'Booking Reshedule',
            dataTable: true,
            dataBody: [
                '+1 Days Prior to arrival',
                'Amendment/Non Refund',
                '-24 Hours Prior to arrival',
                'Non Refund',
                'NO-Show',
                'Non Refund',
            ],
            lastDesc: `Our rates of refund are derived from our above detail,
         and can be summarized as follows: Will not be responsible for 
         any (additional) charges incurred after cancellations/ amendments 
         without confirmation from our side.`,
        },
    ],
    cn: [],
};

export const titleProfile = {
    th: [],
    en: {
        header: 'Profile',
        point: 'Point',
        firstName: 'First Name',
        surName: 'Surname',
        dateOfBirth: 'Date of Birth',
        num: 'Phone Number',
        gender: 'Gender',
        email: 'Email',
        idCard: 'ID card',
        member: 'Member ID',
        editBtn: 'EDIT',
        submitBtn: 'SUBMIT',
        policy: {
            text: 'By signing up or logging in, you acknowledge and agree to',
            secText: 'Luxury29 Car Rental General',
            herfOne: 'Terms of Use',
            and: 'and',
            herfTwo: 'Privacy Policy',
        },
    },
    cn: [],
};

export const cars = [
    {
        id: 1,
        name: 'ROLLS ROYCE (PHANTOM)',
        price: {
            full_day: {
                name: 'Full Day',
                amount: 250000,
            },
            pick_up: null,
        },
        car_brand: {
            id: 1,
            name: 'ROLLS ROYCE',
        },
        car_model: {
            id: 1,
            name: 'PHANTOM',
        },
    },
    {
        id: 2,
        name: 'BENTLEY (Flying SPUR w12)',
        price: {
            full_day: {
                name: 'Full Day',
                amount: 100000,
            },
            pick_up: null,
        },
        car_brand: {
            id: 2,
            name: 'BENTLEY',
        },
        car_model: {
            id: 2,
            name: 'Flying SPUR w12',
        },
    },
    {
        id: 3,
        name: 'MAYBACH (S600)',
        price: {
            full_day: {
                name: 'Full Day',
                amount: 50000,
            },
            pick_up: null,
        },
        car_brand: {
            id: 3,
            name: 'Mercedes Maybach',
        },
        car_model: {
            id: 3,
            name: 'S600',
        },
    },
    {
        id: 4,
        name: 'BENZ (S500E)',
        price: {
            full_day: {
                name: 'Full Day',
                amount: 40000,
            },
            pick_up: null,
        },
        car_brand: {
            id: 3,
            name: 'BENZ',
        },
        car_model: {
            id: 4,
            name: 'S500E',
        },
    },
    {
        id: 5,
        name: 'BENTLEY (BENTAYGA)',
        price: {
            full_day: {
                name: 'Full Day',
                amount: 50000,
            },
            pick_up: null,
        },
        car_brand: {
            id: 2,
            name: 'BENTLEY',
        },
        car_model: {
            id: 5,
            name: 'BENTAYGA',
        },
    },
    {
        id: 6,
        name: 'AUDI (Q7 45TFSI)',
        price: {
            full_day: {
                name: 'Full Day',
                amount: 25000,
            },
            pick_up: null,
        },
        car_brand: {
            id: 2,
            name: 'AUDI',
        },
        car_model: {
            id: 6,
            name: 'Q7 45TFSI',
        },
    },
    {
        id: 7,
        name: 'Toyota Alphard (ALPHARD 2.5 Z Van 2WD 2024)',
        price: {
            full_day: {
                name: 'Full Day',
                amount: 8500,
            },
            pick_up: null,
        },
        car_brand: {
            id: 2,
            name: 'TOYOTA',
        },
        car_model: {
            id: 7,
            name: 'ALPHARD 2.5 Z Van 2WD 2024',
        },
    },
];

export const cardCarDetail = {
    th: [
        {
            id: 1,
            name: 'Rolls royce (PHANTOM)',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 300000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 1,
                name: 'ROLLS ROYCE',
            },
            car_model: {
                id: 1,
                name: 'PHANTOM',
            },
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
            ],
            options: {
                seats: '3 Seats',
                cc: '7,000 cc.',
                driver: 'Driver',
            },
            stock: 'จองรถ',
            price_text: '300,000 ฿',
        },
        {
            id: 2,
            name: 'Bentley (Flying SPUR w12)',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 100000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'BENTLEY',
            },
            car_model: {
                id: 2,
                name: 'Flying SPUR w12',
            },
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
            ],
            options: {
                seats: '4 Seats',
                cc: '6,000 cc.',
                driver: 'Driver',
            },
            stock: 'จองรถ',
            price_text: '100,000 ฿',
        },
        {
            id: 3,
            name: 'Mercedes Benz S600',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 50000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'BENTLEY',
            },
            car_model: {
                id: 5,
                name: 'BENTAYGA',
            },
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
            ],
            options: {
                seats: '4 Seats',
                cc: '6,000 cc.',
                driver: 'Driver',
            },
            stock: 'จองรถ',
            price_text: '50,000 ฿',
        },
        {
            id: 4,
            name: 'Mercedes Benz S500E',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 40000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'AUDI',
            },
            car_model: {
                id: 6,
                name: 'Q7 45TFSI',
            },
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
            ],
            options: {
                seats: '4 Seats',
                cc: '3,000 cc.',
                driver: 'Driver',
            },
            stock: 'จองรถ',
            price_text: '40,000 ฿',
        },
        {
            id: 5,
            name: 'Bentley Bentayga',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 50000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 3,
                name: 'Mercedes Maybach',
            },
            car_model: {
                id: 3,
                name: 'S600',
            },
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
            ],
            options: {
                seats: '4 Seats',
                cc: '6,000 cc.',
                driver: 'Driver',
            },
            stock: 'จองรถ',
            price_text: '50,000 ฿',
        },
        {
            id: 6,
            name: 'Audi Q7 45TFSI',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 25000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 3,
                name: 'BENZ',
            },
            car_model: {
                id: 4,
                name: 'S500E',
            },
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
            ],
            options: {
                seats: '6 Seats',
                cc: '3,000 cc.',
                driver: 'Driver',
            },
            stock: 'จองรถ',
            price_text: '25,000 ฿',
        },
        {
            id: 7,
            name: 'Toyota Alphard',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 40000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'AUDI',
            },
            car_model: {
                id: 6,
                name: 'Q7 45TFSI',
            },
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- เส้นทาง:(กรุงเทพฯ และต่างจังหวัด)',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
            ],
            options: {
                seats: '4 Seats',
                cc: '3,000 cc.',
                driver: 'Driver',
            },
            stock: 'COMING SOON',
        },
    ],
    en: [
        {
            id: 1,
            name: 'Rolls royce (PHANTOM)',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 300000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 1,
                name: 'ROLLS ROYCE',
            },
            car_model: {
                id: 1,
                name: 'PHANTOM',
            },
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration:  8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Driver',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
            ],
            options: {
                seats: '3 Seats',
                cc: '7,000 cc.',
                driver: 'Driver',
            },
            stock: 'BOOK CAR',
            price_text: '300,000 ฿',
        },
        {
            id: 2,
            name: 'Bentley (Flying SPUR w12)',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 100000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'BENTLEY',
            },
            car_model: {
                id: 2,
                name: 'Flying SPUR w12',
            },
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration:  8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Driver',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
            ],
            options: {
                seats: '4 Seats',
                cc: '6,000 cc.',
                driver: 'Driver',
            },
            stock: 'BOOK CAR',
            price_text: '100,000 ฿',
        },
        {
            id: 3,
            name: 'Mercedes Benz S600',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 50000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'BENTLEY',
            },
            car_model: {
                id: 5,
                name: 'BENTAYGA',
            },
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration:  8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Driver',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
            ],
            options: {
                seats: '4 Seats',
                cc: '6,000 cc.',
                driver: 'Driver',
            },
            stock: 'BOOK CAR',
            price_text: '50,000 ฿',
        },
        {
            id: 4,
            name: 'Mercedes Benz S500E',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 40000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'AUDI',
            },
            car_model: {
                id: 6,
                name: 'Q7 45TFSI',
            },
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration:  8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Driver',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
            ],
            options: {
                seats: '4 Seats',
                cc: '3,000 cc.',
                driver: 'Driver',
            },
            stock: 'BOOK CAR',
            price_text: '40,000 ฿',
        },
        {
            id: 5,
            name: 'Bentley Bentayga',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 50000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 3,
                name: 'Mercedes Maybach',
            },
            car_model: {
                id: 3,
                name: 'S600',
            },
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration:  8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Driver',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
            ],
            options: {
                seats: '4 Seats',
                cc: '6,000 cc.',
                driver: 'Driver',
            },
            stock: 'BOOK CAR',
            price_text: '50,000 ฿',
        },
        {
            id: 6,
            name: 'Audi Q7 45TFSI',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 25000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 3,
                name: 'BENZ',
            },
            car_model: {
                id: 4,
                name: 'S500E',
            },
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration:  8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Driver',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
            ],
            options: {
                seats: '6 Seats',
                cc: '3,000 cc.',
                driver: 'Driver',
            },
            stock: 'BOOK CAR',
            price_text: '25,000 ฿',
        },
        {
            id: 7,
            name: 'Toyota Alphard',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 40000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'AUDI',
            },
            car_model: {
                id: 6,
                name: 'Q7 45TFSI',
            },
            detail: [
                '- Service Time Of 10 Hours.',
                '- Routes : Bangkok and Other Provices',
                '- Free Driver',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
            ],
            options: {
                seats: '4 Seats',
                cc: '3,000 cc.',
                driver: 'Driver',
            },
            stock: 'COMING SOON',
        },
    ],
    cn: [
        {
            id: 1,
            name: 'Rolls royce (PHANTOM)',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 300000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 1,
                name: 'ROLLS ROYCE',
            },
            car_model: {
                id: 1,
                name: 'PHANTOM',
            },
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策：满箱送满箱回',
            ],
            options: {
                seats: '3 Seats',
                cc: '7,000 cc.',
                driver: 'Driver',
            },
            stock: '预订车',
            price_text: '300,000 ฿',
        },
        {
            id: 2,
            name: 'Bentley (Flying SPUR w12)',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 100000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'BENTLEY',
            },
            car_model: {
                id: 2,
                name: 'Flying SPUR w12',
            },
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策：满箱送满箱回',
            ],
            options: {
                seats: '4 Seats',
                cc: '6,000 cc.',
                driver: 'Driver',
            },
            stock: '预订车',
            price_text: '100,000 ฿',
        },
        {
            id: 3,
            name: 'Mercedes Benz S600',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 50000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'BENTLEY',
            },
            car_model: {
                id: 5,
                name: 'BENTAYGA',
            },
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策：满箱送满箱回',
            ],
            options: {
                seats: '4 Seats',
                cc: '6,000 cc.',
                driver: 'Driver',
            },
            stock: '预订车',
            price_text: '50,000 ฿',
        },
        {
            id: 4,
            name: 'Mercedes Benz S500E',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 40000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'AUDI',
            },
            car_model: {
                id: 6,
                name: 'Q7 45TFSI',
            },
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策：满箱送满箱回',
            ],
            options: {
                seats: '4 Seats',
                cc: '3,000 cc.',
                driver: 'Driver',
            },
            stock: '预订车',
            price_text: '40,000 ฿',
        },
        {
            id: 5,
            name: 'Bentley Bentayga',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 50000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 3,
                name: 'Mercedes Maybach',
            },
            car_model: {
                id: 3,
                name: 'S600',
            },
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策：满箱送满箱回',
            ],
            options: {
                seats: '4 Seats',
                cc: '6,000 cc.',
                driver: 'Driver',
            },
            stock: '预订车',
            price_text: '50,000 ฿',
        },
        {
            id: 6,
            name: 'Audi Q7 45TFSI',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 25000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 3,
                name: 'BENZ',
            },
            car_model: {
                id: 4,
                name: 'S500E',
            },
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策：满箱送满箱回',
            ],
            options: {
                seats: '6 Seats',
                cc: '3,000 cc.',
                driver: 'Driver',
            },
            stock: '预订车',
            price_text: '25,000 ฿',
        },
        {
            id: 7,
            name: 'Toyota Alphard',
            price: {
                full_day: {
                    name: 'Full Day',
                    amount: 40000,
                },
                pick_up: null,
            },
            car_brand: {
                id: 2,
                name: 'AUDI',
            },
            car_model: {
                id: 6,
                name: 'Q7 45TFSI',
            },
            detail: [
                '- 全天服务 (10小时)',
                '- 路線：曼谷及其他省份',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策：满箱送满箱回',
            ],
            options: {
                seats: '4 Seats',
                cc: '3,000 cc.',
                driver: 'Driver',
            },
            stock: 'COMING SOON',
        },
    ],
};

export const textModal = {
    th: {
        title_top: `อัตราค่าบริการ Aphard 2024`,
        title_bottom: ' แบ่งการชำระเป็น 2 แบบ',
        text_price: 'ราคา 3,500.-',
        text_li_st: [
            `รับส่งสนามบิน-โรงแรม กรุงเทพและปริมณฑล`,
            `เอกสารสำคัญ, สินค้าแบรนด์เนม, ส่งนักเรียน, ส่งศิลปิน, ผู้บริหาร, เยี่ยมชมกิจการ นิทรรศการ`,
            'ราคานี้ ไม่รวมค่าน้ำมัน , ค่าทางด่วน, ค่าจอดรถ และค่าใช้จ่ายอื่นๆ',
            'ราคาต่อเที่ยว /ระยะเวลา 4 ชม.',
            'การให้บริการไม่เกินเวลา 20.00 น. รวมล่วงเวลา',
            'OT ชั่วโมงละ 1,100.- จำกัด ไม่เกิน 2 ชั่วโมง',
            'มัดจำค่าบริการรถเช่าล่วงหน้าครึ่งราคา หรือชำระเต็มจำนวน ส่วนที่เหลือชำระก่อนจบงานพร้อมค่าน้ำมันและทางด่วนค่าจอดรถตามการใช้งานจริง',
            'การยกเลิกก่อนการเดินทาง 1 วัน ทางบริษัทยินดีเปลี่ยนแปลงวันเดินทาง ให้กับท่าน แต่หากยกเลิกน้อย กว่า 1 วัน ก่อนการเดินทาง ทางบริษัทขอสงวนสิทธ์การคืนเงินมัด จำทุกกรณี',
        ],
        text_price_two: 'ราคา 8,500.-',
        text_policy: 'ข้อกำหนด',
        text_type_service: 'รูปแบบการให้บริการ',
        text_li_nd: [
            'ราคานี้ ไม่รวมค่าน้ำมัน , ค่าทางด่วน, ค่าจอดรถ และค่าใช้จ่ายอื่นๆ',
            'เส้นทาง:(กรุงเทพฯ และต่างจังหวัด)',
            'จองก่อนการเดินทาง 1 -2 วัน',
            'จำกัดจำนวนผู้โดยสาร ไม่เกิน 5 ท่าน (ไม่รวมคนขับ)',
            'รับส่งผู้โดยสารแบบเหมาวัน 10 ชั่วโมง',
            'ระยะเวลาการทำงาน 10 ชั่วโมง นับจากเวลานัดหมายของลูกค้า ( จะเริ่มนับจากเวลาใดของวันก็ได้ แต่ต้องไม่เกินเวลา 23:50 ของวันนั้น สำหรับการใช้งาน 1 วัน )',
            'อัตรา Overtime ค่าล่วงเวลา 500 บาทต่อชั่วโมง  ขึ้นอยู่กับการเดินทางและเวลาในการทำงาน',
            'หากมีการเดินทางไปต่างจังหวัด ค่าที่พักคนขับ 500 บาทต่อคืน',
            'มัดจำค่าบริการรถเช่าล่วงหน้าครึ่งราคา หรือชำระเต็มจำนวน ส่วนที่เหลือชำระก่อนจบงานพร้อมค่าน้ำมันและทางด่วนค่าจอดรถตามการใช้งานจริง',
            'การยกเลิกก่อนการเดินทาง 1 วัน ทางบริษัทยินดีเปลี่ยนแปลงวันเดินทางให้กับท่าน แต่หากยกเลิกน้อยกว่า 1 วัน ก่อนการเดินทาง ทางบริษัทขอสงวนสิทธ์การคืนเงินมัดจำทุกกรณี',
        ],
    },
    en: {
        title_top: `Aphard 2024`,
        title_bottom: 'There are 2 payment options available',
        text_price: '3,500.-',
        text_li_st: [
            `Airport-Hotel Pickup: Bangkok and Perimeter`,
            `Important Documents, Luxury Items, School Pickups, Celebrities/Board Personnel/VIPs Itinerary, Attending Meetings/Events`,
            'This rate is not inclusive of fuel after the first 50 kilometers, toll fees, parking fees, and miscellaneous fees',
            'Time Duration: 4 hours',
            'The Service Time is no later than 20.00',
            'Overtime: 1,100 Baht per hour. No later than 2 hours OR further than 30 kilometers',
            `Rental service fee's deposit shall be made prior at 50% or full amount. The remaining would be charged befor clocking out with the additional of fuel, toll fees, parking fees (if any)`,
            'Cancellation one day prior to the itinerary, rescheduling could be discussed. However, shall the cancellation be made on the day reserved, the provider/ we/ Luxury29 Car Rental reserves the right to refund the deposit in any case',
        ],
        text_price_two: '8,500.-',
        text_policy: 'Condition',
        text_type_service: 'Service Options',
        text_li_nd: [
            'This rate is not inclusive of fuel after the first 50 kilometers, toll fees, parking fees, and miscellaneous fees',
            'Routes: Bankok and Other Provinces',
            'Reservation is advised to be made 1-2 days in advance',
            'Maximum capacity: 5 passengers (driver is not included)',
            'Full day service (10 hours)',
            '10 hours period of service starts from the pickup time stated by client. The time could be started at anytime no later than 0.00 AM for one day service.',
            'Overtime: 500 Baht per hour depends on itinerary and clocking time',
            `In case of overnight stay, there would be 500 Baht additional fee per night for the driver's accommodation.`,
            `Rental service fee's deposit shall be made prior at 50% or full amount. The remaining would be charged befor clocking out with the additional of fuel, toll fees, parking fees (if any)`,
            `Cancellation one day prior to the itinerary, rescheduling could be discussed. However, shall the cancellation be made on the day reserved, the Luxury29 Car Rental reserves the right to refund the deposit in any case`,
        ],
    },
    cn: {
        title_top: `Aphard 2024`,
        title_bottom: '有 2 种付款方式可供选择',
        text_price: '3,500.-',
        text_li_st: [
            ` 机场-酒店接机 （曼谷及附近城市)`,
            `重要文件、奢侈品、学校接送、名人/董事会人员/贵宾接送、出席会议/活动`,
            '此价格不包含首个 50 公里后的燃油、过路费、停车费和杂费',
            '持续时间: 4小时',
            '服务时间于20点无迟',
            '如超出原定行程时间，每小时 1,100 泰铢。 不晚于2小时或超过30公里.',
            `租赁服务费需提前支付50%或全额押金。 下班前加收燃油费、过路费、停车费（如有）`,
            '行程前一天取消，可协商改期。 但是, 如果在预订当天取消, Luxury29 Car Rental 保留在任何情况下退还押金的权利.',
        ],
        text_price_two: '8,500.-',
        text_policy: '条件',
        text_type_service: '服务选项',
        text_li_nd: [
            '此价格不包含首个 50 公里后的燃油、过路费、停车费和杂费',
            '路線：曼谷及其他省份',
            '建议提前1-2天预约',
            '最大载客量: 5名乘客 (不含司机)',
            '全天服务 (10小时)',
            '10小时服务期限从客户指定的取件时间开始计算。 对于一日服务，时间可以在不晚于凌晨 0 点的任何时间开始。',
            '如超出原定行程时间, 每小时额外收费500泰铢.',
            '若过夜，需额外支付每晚 500 泰铢司机住宿费',
            '租赁服务费需提前支付50%或全额押金。 下班前加收燃油费、过路费、停车费（如有）',
            '行程前一天取消，可协商改期。 但是, 如果在预订当天取消, Luxury29 Car Rental 保留在任何情况下退还押金的权利.',
            '租赁服务费需提前支付50%或全额押金。 下班前加收燃油费、过路费、停车费（如有）',
            '行程前24小时取消, 可协商改期。 但是，如果在预订当天取消,Luxury29 Car Rental保留在任何情况下退还押金的权利.',
        ],
    },
};

export const privatePolicy = {
    th: {
        head_title: 'นโยบายการคุ้มครองข้อมูลส่วนบุคคล',
        top_title: 'Personal Data Protection Policy',
        text_one: `บริษัท พินเวสเมนท์ จำกัดหรือวีไอพี29ตระหนักถึงความสำคัญในการคุ้มครองข้อมูลส่วนบุคคลของท่านจึงได้จัดทำนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้เพื่อแจ้งให้ท่านในฐานะเจ้าของ 
    ข้อมูลส่วนบุคคลทราบถึงวัตถุประสงค์ของบริษัทในการ เก็บและการใช้ข้อมูล ส่วนบุคคลของท่าน บริษัทมีหน้าที่ตามกฎหมายที่จะต้องเก็บรวบรวมใช้และเปิด เผยข้อมูลส่วนบุคคลของท่านเท่าที่จำเป็นและอยู่ใน 
    ขอบเขตของกฎหมายและบริษัทยืนยันว่าเราได้ ดำเนินการ ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562เพื่อคุ้มครองข้อมูลส่วนบุคคลของท่าน`,
        text_topic_one: {
            title_one: `1.ข้อมูลส่วนบุคคลคืออะไร`,
            title_one_newline: 'ข้อมูลส่วนบุคคลแบ่งออกเป็น สองประเภท ได้แก่',
            desc_one: `ข้อมูลส่วนบุคคล (ทั่วไป) หมายถึงข้อมูลส่วนบุคคลของคนที่มีชีวิตอยู่ที่สามารถระบุตัวตนของบุคคลนั้นได้ไม่ว่าทางตรง 
        หรือทางอ้อม เช่น ชื่อ นามสกุล ที่อยู่ email address หมายเลขบัตรประจำตัวประชาชนหมายเลขโทรศัพท์ เป็นต้น`,
            desc_two: `ข้อมูลส่วนบุคคลที่อ่อนไหว (Sensitive Personal Data) ได้แก่ 
        ข้อมูลส่วนบุคคลที่ต้องได้รับการคุ้มครองเป็น พิเศษ ซึ่งจำ เป็นต้องให้ความระมัดระวังเป็น พิเศษในการเก็บรวบรวม ใช้หรือเปิดเผย ข้อมูลส่วนบุคคลประเภทนี้ เช่น ข้อมูลส่วนบุคคลเกี่ยวกับเชื้อชาติ เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ 
        ศาสนาหรือปรัชญาพฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม และข้อมูลชีวภาพ หรือข้อมูลตาม   
        ที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคล ประกาศกำหนด`,
        },
        text_topic_two: {
            title_one: `2. บริษัทได้รับข้อมูลส่วนบุคคลของท่านจากไหน`,
            desc_one: `2.1 ข้อมูลส่วนบุคคลที่บริษัทได้รับจากท่านโดยตรงบริษัทได้รับข้อมูลส่วนบุคคลของท่านโดยตรงผ่านช่องทางปกติ และช่องทางออนไลน์ ในกรณีต่อไปนี้:`,
            text_dot: [
                `ท่านติดต่อสอบถามหรือขอรับข้อมูลจาก บริษัท`,
                `ท่านลงทะเบียนโดยการสร้างบัญชีผ่าน เว็บไซต์หรือแอปพลิเคชัน`,
                `ท่านได้ทำแบบสอบถามหรือติดต่อกับบริษัททางโทรศัพท์ อีเมล หรือผ่านพนักงานของบริษัท`,
                `ท่านเข้าเว็บไซต์ของบริษัท โดยทางบริษัทจะเก็บข้อมูลที่ได้มาจากคุกกี้ (Cookies) ข้อมูลเหล่านี้ช่วยให้ บริษัทสามารถจัดให้มีการบริการที่ดีขึ้น เร็วขึ้น ปลอดภัยขึ้นและเพื่อความเป็นส่วน ตัวของท่าน เมื่อท่านใช้บริการและ/หรือเข้าสู่แพลตฟอร์ม cookies คือtext files ที่อยู่ใน 
            คอมพิวเตอร์ของท่านที่ใช้เพื่อจัด เก็บรายละเอียดข้อมูล log การใช้งานอินเทอร์เน็ต ของท่านหรือพฤติกรรมการเยี่ยมชมเว็บไซต์ของท่าน ท่านสามารถศึกษารายละเอียดเพิ่มเติมในเรื่อง Cookies ได้จาก นโยบายคุกกี้`,
            ],
            title_two_two: `2.2 ข้อมูลส่วนบุคคลของท่านที่บริษัทได้รับมา จากหน่วยงาน/บุคคลภายนอก (Third Party)
        บริษัทอาจได้รับข้อมูลส่วนบุคคลของท่านมาจากหน่วยงาน/บุคคลภายนอกที่เป็นเครือข่าย กับบริษัทในการดำเนินการเกี่ยวกับการเก็บรวบรวมใช้หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของบริษัทซึ่งข้อมูลส่วนบุคคลของท่านจะไม่ถูกนำไปใช้นอกเหนือจาก 
        วัตถุประสงค์ ที่บริษัทได้กำหนดหรือสั่งไว้และบริษัท ได้กำหนด ให้หน่วยงาน/บุคคลภายนอกต้องรักษาความลับ และคุ้มครองข้อมูลส่วนบุคคลของท่านตาม มาตรฐาน ของกฎหมายคุ้มครองข้อมูลส่วน บุคคลของประเทศไทย`,
        },
        text_topic_three: `3.ประเภทข้อมูลส่วนบุคคลของท่านที่บริษัทเก็บ รวบรวม
    บริษัทเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเท่าที่จำเป็นในการดำเนินงานตามวัตถุประสงค์ของบริษัท โดยสามารถจำแนกประเภทได้ดังนี้`,
        text_table: {
            thead: ['ประเภทข้อมูล', 'รายละเอียด'],
            tbody: [
                {
                    id: 1,
                    data_type: '1. ข้อมูลการติดต่อ',
                    desc: 'ได้แก่ ชื่อ นามสกุล เลขที่บัตรประชาชน เพศ อายุ ที่อยู่ ปัจจุบัน เบอร์โทรศัพท์ อีเมล',
                },
                {
                    id: 2,
                    data_type: '2. ข้อมูลด้านการเงิน',
                    desc: `ได้แก่ ข้อมูล เอกสาร หลักฐานและรายละเอียดเกี่ยวกับการชำระค่าธรรมเนียม 
หรือค่าบริการ ประวัติการชำระเงินและ หลักฐานการ ชำระเงิน  ตลอดจนข้อมูลเกี่ยวกับบัญชีธนาคาร`,
                },
                {
                    id: 3,
                    data_type: '3. ข้อมูลการสื่อสาร',
                    desc: 'ได้แก่ข้อมูลการให้บริการ บันทึกข้อมูลการสนทนากับ เจ้าหน้าที่ข้อมูลการสื่อสารทางอีเมล',
                },
                {
                    id: 4,
                    data_type: '4. ข้อมูลด้านเทคนิค',
                    desc: `ได้แก่ หมายเลข IP Address ของคอมพิวเตอร์ชนิดของเบราว์เซอร์การตั้งค่าเรื่อง 
        เขต เวลา (time zone) ที่ตั้ง (location) ระบบปฏิบัติการ แพลตฟอร์มและเทคโนโลยีของ อุปกรณ์ที่ใช้เข้าเว็บไซต์`,
                },
                {
                    id: 5,
                    data_type: '5. ข้อมูลการใช้งานเว็บไซต์',
                    desc: `ข้อมูลการใช้งานเว็บไซต์ ได้แก่ หน้าเว็บไซต์ที่ท่านเข้าถึงก่อนที่ท่านจะเข้าสู่แพลตฟอร์ม หน้าเว็บไซต์ที่ท่านเข้าชมจำนวนเวลาที่ท่านใช้ในการชม 
        หน้าเว็บไซต์ สินค้า บริการ หรือข้อมูลที่ท่านค้นหาใน แพลตฟอร์มเวลาและวันที่เข้าชมเว็บไซต์ข้อมูลการกดดู เว็บไซต์ `,
                },
            ],
        },
        text_topic_four: '4. วัตถุประสงค์ในการใช้ข้อมูลส่วนบุคคลของท่าน',
        text_detail_four: `บริษัทเราใช้ข้อมูลส่วนบุคคลของท่านภายใต้ขอบเขตของ กฎหมายคุ้มครองข้อมูล และเก็บรวบรวม
    ข้อมูลเพียงเท่าที่จำเป็น`,
        text_dot_four: [
            {
                id: 1,
                title: `เพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัท (Legitimate Interests) หรือประโยชน์โดยชอบด้วยกฎหมายของบุคคลภายนอก หรือประโยชน์โดยชอบด้วยกฎหมายของท่าน โดยที่ไม่ทำให้สิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของท่านนั้นลดน้อยลง`,
            },
            {
                id: 2,
                title: `เพื่อการปฏิบัติตามสัญญาที่ท่านเป็นคู่สัญญา หรือเพื่อใช้ในการดำเนินการตามคำขอของท่านก่อนเข้าทำสัญญา`,
            },
            {
                id: 3,
                title: `เพื่อเป็นการปฏิบัติตามกฎหมายของบริษัท หรือเพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล`,
            },
        ],
        text_desc_four: `บริษัทได้สรุปฐานการประมวลผลข้อมูลส่วนบุคคลที่ 
    ชอบธรรม (Lawful Basis of Processing) ที่บริษัทใช้ในการดำเนินการตามวัตถุประสงค์ตามตาราง ข้างล่าง`,
        text_table_four: {
            text_head: [
                'วัตถุประสงค์',
                'ประเภทข้อมูล',
                'ฐานการประมวลผล ข้อมูลส่วนบุคคลที่ชอบธรรม',
            ],
            text_row: [
                {
                    id: 1,
                    data_type: {
                        normal_text: `1)ให้บริการตามสัญญากับลูกค้า`,
                        text_dot: [],
                    },
                    desc: ['ข้อมูลการติดต่อ', 'ข้อมูลการเงิน'],
                    process: [
                        `เพื่อการจำเป็นเพื่อการปฏิบัติตามสัญญาให้บริการกับท่านหรือดำเนินการตามคำขอของท่านเพื่อเข้า ทำสัญญาการให้บริการ`,
                    ],
                },
                {
                    id: 1,
                    data_type: {
                        normal_text: `2)ให้บริการลูกค้า (Customer Service) ซึ่งรวมถึง`,
                        text_dot: [
                            `การติดต่อลูกค้ากับลูกค้าเพื่อดำเนินตามคำขอของลูกค้า`,
                            `ให้บริการหลังการขาย`,
                            'จัดการด้านสิทธิ ประโยชน์ของลูกค้า',
                            'รับเรื่องร้องเรียนของ ลูกค้า',
                            'รับแจ้งประสานงานเพื่อแก้ ปัญหาการใช้งาน ของ ลูกค้า',
                            'อำนวยความสะดวกลูกค้าใน การตรวจสอบ ข้อมูล การชำระเงินและ การจัดการสิทธิ ประโยชน์ของลูกค้า',
                        ],
                    },
                    desc: [
                        '- ข้อมูลการติดต่อ',
                        '- ข้อมูลการเงิน',
                        '- ข้อมูลกาสื่อสาร',
                    ],
                    process: [
                        `1) เพื่อการจำเป็นเพื่อการปฏิบัติตามสัญญาการให้บริการ`,
                        `2) เพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทในการ
            ให้บริการ ลูกค้า (Customer Service) เพื่ออำนวยความ สะดวกให้ลูกค้าในการถึงเข้าบริการ
            `,
                    ],
                },
                {
                    id: 1,
                    data_type: {
                        normal_text: `4)เพื่อส่งข่าวสารโปรโม
                ชั่นรวมถึงการเข้าร่วมกิจกรรมด้านการตลาด`,
                        text_dot: [],
                    },
                    desc: ['- ข้อมูลการติดต่อ'],
                    process: [
                        `1) เพื่อประโยชน์โดยชอบด้วยกฎหมายของเราในการ นำเสนอข่าวสารและโปรโมชั่นให้กับลูกค้า`,
                        `2) ถ้าเป็นเรื่องการตลาดแบบตรง (Direct Marketing) เราจะขอความยินยอมโดยชัดแจ้งจากท่านในการให้เรา นำข้อมูลส่วนบุคคลท่านไปทำการตลาดแบบตรง`,
                    ],
                },
            ],
        },
        text_topic_five: `5. กิจกรรมทางการตลาดและการส่งเสริมการตลาด`,
        text_topic_five_detail: `บริษัทอาจดำเนินกิจกรรมทางการตลาดและการส่งเสริมการตลาดหรือให้บุคคลภายนอกที่บริษัทมีข้อตกลงด้วย ดำเนินการ ในกรณีดังต่อไปนี้`,
        text_dot_five: [
            `ในกรณีที่บริษัทนำข้อมูลส่วนบุคคลของท่านไปใช้ในการทำการตลาดแบบตรง (Direct Marketing) กับท่าน บริษัทจะดำเนินการได้เฉพาะกรณีที่ท่านให้ความ ยินยอมโดยชัดแจ้ง และบริษัทได้มีการแจ้งถึงวัตถุประสงค์ของการใช้ ข้อมูลท่านเพื่อการทำการตลาดแบบตรงนั้นกับ ท่านแล้ว ทั้งนี้ ท่านสามารถยกเลิกการให้ความยินยอมกับบริษัทในเรื่องการ ทำการตลาดแบบตรง ได้ตลอดเวลา โดยผ่านระบบการยกเลิกการรับข้อมูลของบริษัท`,
            `ในการทำการตลาดแบบตรงกับท่าน บริษัทใช้ข้อมูลของท่านในการวิเคราะห์พฤติกรรมเพื่อนำเสนอ บริการที่ตรงกับความต้องการของท่าน`,
            `ในกรณีการเข้าร่วมกิจกรรมส่งเสริมการขายของบริษัท เช่น กิจกรรมการเล่นเกม หรือกิจกรรม ในช่องทาง Social Network ต่างๆ เพื่อประชาสัมพันธ์สินค้าและ บริการของบริษัทให้บุคคลทั่วไป ทราบ บริษัทจะไม่นำข้อมูลของท่านไปประมวลผลเพื่อทำการตลาดแบบตรง (Direct Marketing) ยกเว้นท่านจะให้ความยินยอมโดยชัดแจ้งให้บริษัทนำข้อมูลส่วนบุคคลไปทำการตลาดแบบตรงได้ โดยบริษัทจะแจ้งท่านถึงวัตถุประสงค์ของการใช้ ข้อมูลท่านเพื่อการทำการตลาดแบบตรง และท่านสามารถยกเลิกการให้ความยินยอมกับบ ริษัทในเรื่องการทำการตลาดแบบตรงได้ตลอด เวลาโดยผ่านระบบการยกเลิกการรับข้อมูลของ บริษัท `,
        ],
        text_topic_six: '6. การเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคล',
        text_six_one: `6.1บริษัทไม่เปิดเผยข้อมูลส่วนบุคคลของท่านให้กับ หน่วยงานบุคคลภายนอกยกเว้นกรณีที่มีความจำเป็นที่ต้องเปิดเผยให้หน่วยงาน/บุคคลภายนอกเพื่อ บรรลุวัตถุประสงค์เกี่ยวกับการดำเนินงานหรือ เป็นหน่วยงานมีหน้าที่ตามกฎหมายในการร้องขอให้ บริษัทเปิดเผยข้อมูล หน่วยงาน/บุคคลภายนอก เหล่า นี้ได้แก่`,
        text_six_one_dot: [
            `บริษัทในเครือ`,
            `แพลตฟอร์มโฆษณาออนไลน์`,
            `บริษัทหรือที่ปรึกษาด้านการตลาด`,
            `หน่วยงานกำกับดูแล เช่น สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล`,
            `หน่วยงานที่บริษัทจ้างให้เก็บข้อมูล`,
        ],
        text_six_two: `6.2 บริษัทกำหนดให้หน่วยงาน/บุคคลภายนอกที่บริษัทเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลของท่านข้างต้นต้องรักษาความลับและคุ้มครองข้อมูลส่วนบุคคลของท่านตามมาตรฐานที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลของประเทศไทย กำหนด และใช้ข้อมูลส่วนบุคคลของท่านได้เฉพาะตาม วัตถุประสงค์ที่บริษัทได้กำหนดหรือมีคำสั่งให้ บุคคลภายนอกนั้นดำเนินการบุคคลภายนอกจะไม่สามารถใช้ข้อมูลส่วนบุคคลของท่านนอกเหนือจาก วัตถุประสงค์ดังกล่าวได้`,
        text_six_three: `6.3 เมื่อถูกร้องขอ บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของท่านให้หน่วยงานที่เกี่ยวข้องเพื่อปฏิบัติตามกฎหมาย เพื่อบรรลุวัตถุประสงค์ เช่น เพื่อป้องกันการข่มขู่ต่อชีวิตหรือร่างกายเพื่อวัตถุประสงค์ในการบังคับ ใช้กฎหมาย การตั้งข้อหา การใช้สิทธิ์ปกป้องสิทธิทางกฎหมาย และการป้องกันกรณีฉ้อโกง เป็นต้น`,
        text_topic_seven:
            '7.การเก็บรักษาและระยะเวลาในการเก็บรักษา ข้อมูลส่วนบุคคล',
        text_seven_one: `1.บริษัทเก็บข้อมูลส่วนบุคคลของท่านในระบบงานสาร
    สนเทศของบริษัท และจัดให้มีการรักษาความปลอดภัย ที่มีมาตรฐานเพื่อให้ระบบงานสารสนเทศของบริษัท และ การใช้ข้อมูลส่วนบุคคลของท่านมีความปลอดภัย โดยมีมาตรการดังนี้`,
        text_dot_seven: [
            `จำกัดการเข้าถึงข้อมูลส่วนบุคคลที่อาจจะเข้าถึงได้โดยเจ้าหน้าที่หรือพนักงาน`,
            `จัดให้มีวิธีการทางเทคโนโลยีเพื่อป้องกันไม่ให้มีการ เข้าสู่ระบบคอมพิวเตอร์ที่ไม่ได้รับอนุญาต`,
            `จัดการทำลายข้อมูลส่วนบุคคลของท่านเพื่อความ ปลอดภัยเมื่อข้อมูลดังกล่าวไม่จำเป็นสำหรับ วัตถุประสงค์ในการเก็บข้อมูลของบริษัทอีกต่อไป`,
            `มีกระบวนการจัดการกับกรณีละเมิดข้อมูล ส่วนบุคคลหรือกรณีที่ต้องสงสัยและต้องแจ้ง เหตุการละเมิดข้อมูลส่วนบุคคลแก่ สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล ตามเงื่อนไขที่กฎหมายกำหนด`,
        ],
        text_seven_two: `2. ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล`,
        text_dot_seven_nd: [
            `บริษัทเก็บข้อมูลส่วนบุคคลของท่านไว้ในระยะเวลาที่จำเป็นเพื่อดำเนินการตามวัตถุประสงค์ของ บริษัท และเป็นไปตามระยะเวลาที่กำหนดโดยมาตรฐานที่ได้รับการยอมรับ เช่น มาตรฐานทางบัญชี มาตรฐานทางกฎหมายและกฎระเบียบอื่นที่เกี่ยวข้อง`,
            `ในการกำหนดระยะเวลาในการเก็บรักษาข้อมูล บริษัทจะพิจารณาถึงจำนวน ลักษณะการใช้งาน วัตถุประสงค์ในการให้บริการ ความอ่อนไหวของข้อมูลส่วนบุคคล และความเสี่ยงที่อาจเกิดขึ้น จากการใช้ข้อมูลส่วนบุคคลโดยมิชอบ และระยะเวลาที่กำหนดโดยกฎหมายที่เกี่ยวข้อง`,
            `ข้อมูลส่วนบุคคลของท่านที่ได้มาจากคุกกี้ (Cookies) ในการเก็บข้อมูลเมื่อท่านเข้าใช้เว็บไซต์ บริษัท จะเก็บไว้ไม่เกิน 13 เดือน หรือตามที่กฎหมายที่เกี่ยวข้องกำหนด`,
        ],
        text_topic_eight: `8. นโยบายเรื่องคุกกี้ (Cookies)`,
        text_eight_one: `1. Cookies เพื่อการทำงานของเว็บไซต์ (Functionality Cookies):
    บริษัทใช้ Cookies เพื่อพัฒนาการทำงานของเว็บไซต์ (Functionality Cookies) ในการจัดเก็บข้อมูล การเข้าเยี่ยมชมเว็บไซต์ของท่านเพื่อช่วยให้บริษัท สามารถจัดให้มีการบริการที่ดีขึ้น เร็วขึ้น ปลอดภัยขึ้น Cookiesประเภทนี้ช่วยให้บริษัทจดจำอุปกรณ์หรือ 
    เบราว์เซอร์ของท่านเพื่อให้บริษัทสามารถจัดทำเนื้อหาให้เหมาะสมกับความสนใจส่วนบุคคลของท่านได้รวดเร็วขึ้นและช่วยให้การบริการและแพลตฟอร์มสะดวกสบายและ เป็นประโยชน์ต่อท่าน มากขึ้น ในการปิดใช้งาน Cookies นี้ ท่านสามารถตั้งค่าอุปกรณ์ของคุณโดยดูวิธีการช่วย 
    เหลือของเบราว์เซอร์หรืออุปกรณ์ของท่าน`,
        text_eight_two: `2. Cookies เพื่อการวิเคราะห์ (Analytic cookies): บริษัทใช้ Analytics Cookies ที่ให้บริการโดย บุคคลภายนอกเพื่อเก็บข้อมูลว่าผู้เข้าชมเว็บไซต์ของ บริษัทมีการใช้งานอย่างไรบริษัททำข้อมูลผู้เข้าชมเว็บไซต์เป็นข้อมูลนิรนามทั้งหมด(Anonymization)
    และส่งผ่านไปที่บุคคลภายนอกผู้ที่จะสามารถใช้ข้อมูลเหล่านี้หรือเปิดเผยข้อมูลเหล่านี้ให้กับบุคคลอื่นที่ประมวลผลข้อมูลให้ตามที่กฎหมายอนุญาตบุคคลภายนอกจะไม่รวมข้อมูลของท่านที่ได้ไปจากเว็บไซต์เรากับข้อมูลที่บุคคลภายนอกมีอยู่แล้วท่านสามารถเลือกปิดการใช้งาน Cookies ลักษณะนี้ บนหน้าเว็บไซต์บริษัท`,
        text_topic_nine: `9. นโยบายการคุ้มครองข้อมูลส่วนบุคคลของ Website อื่น`,
        text_nine_desc: `นโยบายความเป็นส่วนตัวฉบับนี้ใช้เฉพาะสำหรับการให้ บริการของบริษัทและการใช้งานเว็บไซต์ของ บริษัท เท่านั้นหากท่านได้กด link ไปยังเว็บไซต์อื่น (แม้จะผ่านช่องทางในเว็บไซต์ของบริษัทก็ตาม)ท่านจะ ต้องศึกษาและปฏิบัติตามนโยบายความเป็นส่วนตัวที่ ปรากฏในเว็บไซต์นั้นๆ แยกต่างหากจาก ของบริษัท`,
        text_topic_ten: `10. การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล`,
        text_ten_desc: `บริษัทจะตรวจสอบประสิทธิภาพของนโยบายความเป็น ส่วนตัวเป็นประจำทั้งนี้หากมีการเปลี่ยนแปลงนโยบาย ความเป็นส่วนตัวการเปลี่ยนแปลงนโยบายดังกล่าวจะถูกประกาศหรือตีพิมพ์ไว้บนเว็บไซต์หรือแพลตฟอร์มอื่น ของบริษัท`,
        text_topic_eleven: `11. สิทธิของเจ้าของข้อมูลส่วนบุคคล`,
        text_eleven_detail: `ในฐานะเจ้าของข้อมูลส่วนบุคคลท่านมีสิทธิในการดำเนินการตามขอบเขตที่พระราชบัญญัติคุ้มครองข้อมูล ส่วนบุคคล พ.ศ. 2562 ให้กระทำได้ ดังต่อไปนี้`,
        text_eleven_desc: [
            `1. ท่านมีสิทธิเพิกถอนความยินยอม (Right to withdraw Consent) ในการประมวลผลข้อมูลส่วนบุคคลท่านได้ให้ ความยินยอมกับบริษัทได้ตลอดระยะเวลาที่บริษัทเก็บ ข้อมูลส่วนบุคคลของท่าน`,
            `2. ท่านมีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลของท่าน (Right of Access) และขอให้บริษัททำสำเนาข้อมูล ส่วนบุคคลดังกล่าวให้แก่ท่าน`,
            `3. ท่านมีสิทธิให้บริษัทแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง หรือเพิ่มเติมข้อมูลที่ไม่สมบูรณ์(Right to Rectification)`,
            `4. ท่านมีสิทธิ ในการขอให้บริษัททำการลบข้อมูลของ ท่านด้วยเหตุบางประการได้ (Right to Erasure)`,
            `5. ท่านมีสิทธิขอให้บริษัทระงับใช้ข้อมูลส่วนบุคคลของท่านด้วยเหตุผลบางประการได้ (Right to Restriction of Processing)`,
            `6. ท่านมีสิทธิในการโอนย้ายข้อมูลส่วนบุคคลที่ท่านให้ไว้ กับบริษัทไปยังผู้ควบคุมข้อมูลรายอื่นหรือตัวท่านเอง ด้วยเหตุผลบางประการได้ (Right to Data Portability)`,
            `7. ท่านมีสิทธิคัดค้านการประมวลผลข้อมูลของท่านด้วย เหตุผลบางประการได้ (Right to Object)`,
            `8. ท่าน มีสิทธิเรียกร้องให้ชดใช้ค่าสินไหมทดแทน กรณีที่การละเมิด ข้อมูลส่วนบุคคลก่อให้เกิดความ เสียหายในการใช้สิทธิดังกล่าว ท่านสามารถติดต่อบริษัท ในฐานะผู้ควบคุมข้อมูลส่วนบุคคลตามที่อยู่ข้างล่างเพื่อ ดำเนินการยื่นคำร้องขอดำเนินการตามสิทธิข้างต้นได้ และบริษัทจะดำเนินการตามคำขอของท่านภายใน 30 วัน บริษัทจะดำเนินการพิจารณาคำขอของท่านตามขอบเขต ของพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล 
        พ.ศ. 2562 และในกรณีที่บริษัทปฏิเสธ คำขอของท่าน บริษัทจะให้เหตุผลการปฏิเสธคำขอของท่านด้วย`,
        ],
        text_topic_twelve: `12. ช่องทางการติดต่อกับบริษัท`,
        text_twelve_content: [
            `บริษัท พินเวสเมนท์ จำกัด`,
            `อีเมล : vip29@pinvestment.co.th`,
            `สำนักงานแห่งใหญ่ : เลขที่ 200 อาคารจัสมิน อินเตอ ร์เนชั่นแนล ทาวเวอร์ ชั้นที่ 31 หมู่ที่ 4 ถนนแจ้งวัฒนะ ตำบลปากเกร็ด อำเภอปากเกร็ด จังหวัดนนทบุรี 11120`,
        ],
    },
    en: {
        head_title: 'Personal Data Protection Policy',
        top_title: 'Personal Data Protection Policy',
        text_one: `Pinvestment.Co.,Ltd or VIP29.recognizes the importance of protecting your personal information. Therefore, this Personal Data Protection Policy has been prepared to inform you, as the data subject, of the Company's objectives in collecting and using your personal data. The Company is legally obligated to collect, use and disclose your personal data to the extent necessary and within the scope of the law. And the company confirms that we have proceeded in accordance with the Personal Data Protection Act B.E. 2562 to protect your personal data.`,
        text_topic_one: {
            title_one: `1. What is personal data?`,
            title_one_newline: 'Personal data is divided into two categories',
            desc_one: `Personal data (general) means personal data of a living person that can identify that person either directly or indirectly, such as name, surname, address, email address, identification number. phone number`,
            desc_two: `Sensitive Personal Data is personal data that requires special protection. Which requires special caution in collecting, using or disclosing this type of personal information, such as personal information about race, ethnicity, political opinions doctrine Religion or Philosophy of Sexual Behavior Criminal record Health information Disability union information genetic information and biological data or information as prescribed by the Personal Data Protection Committee`,
        },
        text_topic_two: {
            title_one: `2. Where does the company get your personal data from?`,
            desc_one: `2.1 Personal data that the Company directly receives from you the company receives your personal information directly through normal channels. and online channels In the following cases:`,
            text_dot: [
                `You inquire or obtain information from the company.`,
                `You register by creating an account through the website or application.`,
                `You have completed a questionnaire or contacted the Company by telephone, e-mail or through a member of the Company.`,
                `You enter the company's website. The Company collects information obtained from cookies (Cookies). The company can provide a better, faster, safer service and for your privacy. when you use the service and/or access to the platform. Cookies are text files located on your computer that are used to store detailed log information of your internet usage or your browsing behavior. You can learn more details about Cookies from Cookie Policy`,
            ],
            title_two_two: `2.2 Your personal information that the company receives from agencies / third parties (Third Party)
            The Company may obtain your Personal Data from agencies/third parties affiliated with the Company in order to carry out the collection, use or disclosure of Personal Data in accordance with the instructions or on behalf of the Company. Your personal information will not be used for other than the purposes specified or ordered by the company and the company has required agencies / third parties to maintain the confidentiality and protect your personal data in accordance with the standards of the personal data protection laws of Thailand.
            `,
        },
        text_topic_three: `3. Types of your personal information that the company collects
        The company collects your personal data as necessary to operate the company's objectives. which can be classified as follows`,
        text_table: {
            thead: ['Type of information', 'Detail'],
            tbody: [
                {
                    id: 1,
                    data_type: '1. Contact Information',
                    desc: 'Such as name, surname, ID card number, sex, age, current address, phone number, email',
                },
                {
                    id: 2,
                    data_type: '2. Financial information',
                    desc: `Such as information, documents, evidence and details about payment of fees or service charges, payment history and evidence of payment, as well as information about bank accounts.`,
                },
                {
                    id: 3,
                    data_type: '3. Communication data',
                    desc: 'Such as trading information Record conversations with staff, email communication information',
                },
                {
                    id: 4,
                    data_type: '4. Technical data',
                    desc: `Such as the IP Address of the computer browser type Time zone setting, location, operating system platform and technology of the device used to access the website`,
                },
                {
                    id: 5,
                    data_type: '5. Website Usage Information',
                    desc: `Website Usage Data includes the website pages you accessed before you entered the Platform, the website pages you visit The amount of time you spend viewing the website's pages, products, services or information you search for on the platform Time and date of visit to the website website click data`,
                },
            ],
        },
        text_topic_four: '4. Purpose of using your personal data',
        text_detail_four: `We use your personal data within the scope of data protection laws and only collects information as necessary:`,
        text_dot_four: [
            {
                id: 1,
                title: `For the legitimate interests of the company (Legitimate Interests) or the legitimate interests of third parties. or your legitimate interests without compromising your fundamental rights in personal data`,
            },
            {
                id: 2,
                title: `For the performance of a contract that you are a party to or to be used to process your request before entering into a contract`,
            },
            {
                id: 3,
                title: `To comply with the law of the company or to prevent or suppress a danger to a person's life, body or health.`,
            },
        ],
        text_desc_four: `The Company has summarized the Lawful Basis of Processing on which it uses for the Purposes in the table below.`,
        text_table_four: {
            text_head: [
                'Objective',
                'Type of Information',
                'processing base fair personal information',
            ],
            text_row: [
                {
                    id: 1,
                    data_type: {
                        normal_text: `1)Providing services according to contracts with customers`,
                        text_dot: [],
                    },
                    desc: ['Contact information', 'Financial information'],
                    process: [
                        `For the necessity of performing the service contract with you or to process your request to enter into a service contract`,
                    ],
                },
                {
                    id: 2,
                    data_type: {
                        normal_text: `2)Provide customer service, which includes`,
                        text_dot: [
                            `Customer-to-customer contact to fulfill customer requests.`,
                            `provide after-sales service.`,
                            'Manage customer benefits.',
                            'receive customer complaints.',
                            'Get notified and coordinate to solve customer usage problems.',
                            'Facilitate customers to verify their information, make payments, and manage their benefits.',
                        ],
                    },
                    desc: [
                        '- Contact information',
                        '- Financial information',
                        '- Communication data',
                    ],
                    process: [
                        `1) Necessary for the performance of the service contract`,
                        `2) For the legitimate interests of the company in providing customer service to facilitate customers in accessing the service.`,
                    ],
                },
                {
                    id: 3,
                    data_type: {
                        normal_text: `3) To analyze information that is useful for improving the Company's services::`,
                        text_dot: [
                            'To report statistics on the number of customers for marketing planning.',
                            'in website development Solve technical problems arising in the use of the Website and improve the customer experience in receiving the services provided by the Company.',
                        ],
                    },
                    desc: [
                        '- Contact information',
                        '- Technical information',
                        '- Website usage information',
                    ],
                    process: [
                        `For the Company's legitimate interests in using information and website usage statistics to develop services for customers and improving the website to meet the needs of customers`,
                    ],
                },
                {
                    id: 1,
                    data_type: {
                        normal_text: `4)To send promotion news including participating in marketing activities`,
                        text_dot: [],
                    },
                    desc: ['- Contact information'],
                    process: [
                        `1) It is for our legitimate interest to present news and promotions to customers`,
                        `2) If it is direct marketing, we will request your explicit consent for us to use your personal data for direct marketing`,
                    ],
                },
            ],
        },
        text_topic_five: `5. Marketing activities and marketing promotion`,
        text_topic_five_detail: `The Company may carry out marketing and promotional activities or engage third parties with whom the Company has an agreement. In the following cases`,
        text_dot_five: [
            `In the event that the company uses your personal data for direct marketing (Direct Marketing) to you, the company can do so only if you have given your explicit consent and the company has informed you about the purpose of using your data for direct marketing. You can revoke your consent to the company for direct marketing at any time through the opt-out system. company information`,
            `in direct marketing to you The Company uses your information to analyze behavior in order to offer you services tailored to your needs.`,
            `In the case of participating in the company's promotional activities such as gaming activities Or activities on various social networks to promote the company's products and services to the general public. The Company will not process your personal data for direct marketing unless you have given your express consent to allow the Company to use your personal data for direct marketing. The company will inform you about the purpose of using your data for direct marketing. And you can revoke your consent to direct marketing at any time through the Company's opt-out system.`,
        ],
        text_topic_six: '6. Disclosure or sharing of personal information',
        text_six_one: `6.1 The company does not disclose your personal information to agencies / third parties. except in case of necessity that must be disclosed to third parties/parties in order to achieve the objectives related to the operation or is an agency having legal duty to request the Company to disclose information These entities/parties include:`,
        text_six_one_dot: [
            `Affiliates`,
            `online advertising platform`,
            `marketing company or consultant`,
            `Regulatory agencies such as the Office of the Personal Data Protection Commission`,
            `Departments hired by the company to collect data`,
        ],
        text_six_two: `6.2 The company requires agencies / third parties that the company discloses or shares your personal data above to maintain the confidentiality and protect your personal data in accordance with the standards set by the personal data protection laws of Thailand and can use your personal data only for the purposes specified by the company or instructed by such third party Third parties will not be able to use your personal information for other purposes.`,
        text_six_three: `6.3 When requested The Company may disclose your personal information to relevant agencies to comply with the law. To achieve an objective, such as to prevent a threat to life or body. For law enforcement purposes, making charges, exercising legal rights protections. and prevention of fraud, etc.`,
        text_topic_seven: '7. Retention and period to keep personal data',
        text_seven_one: `1. The Company stores your personal information in the Company's information system. and provide security with standards for the Company's information system and the use of your personal information is safe with the following measures`,
        text_dot_seven: [
            `Restrict access to personal information that may be accessed by officers or employees.`,
            `Provide technological means to prevent unauthorized access to computer systems.`,
            `Manage to destroy your personal information for security purposes when such information is no longer needed for the Company's storage purposes.`,
            `There is a process for handling cases of personal data breaches. Or in the case of suspicion and having to report the incident of personal data breach to the Office of the Personal Data Protection Commission according to the conditions required by law.`,
        ],
        text_seven_two: `2. Period of retention of personal data`,
        text_dot_seven_nd: [
            `The Company retains your personal data for the period necessary to carry out the Company's objectives and in accordance with the period specified by recognized standards such as accounting standards. legal standards and other related regulations`,
            `To determine the period of data retention The company will consider the amount Usage purpose of service Sensitivity of personal data and potential risks from misuse of personal information and the period specified by relevant laws.`,
            `Your personal information obtained from cookies (Cookies) to collect information when you access the website, the company will keep it for no more than 13 months or as required by relevant laws.`,
        ],
        text_topic_eight: `8. Cookies Policy`,
        text_eight_one: `1. Cookies for website operation (Functionality Cookies):
        The Company uses cookies to improve the functionality of the website (Functionality Cookies) to collect information about your website visits to help the Company. can provide a better, faster, safer service. These types of cookies help the Company remember your device or browser so that the Company can make content more quickly suited to your individual interests. and make the service and platform convenient and more beneficial to you. To disable these cookies, you can set up your device by referring to your browser or device's help instructions.`,
        text_eight_two: `2. Analytic cookies: บริษัทใช้ Analytics Cookies The Company uses Analytics Cookies provided by third parties to collect information about how visitors to the Company's Websites use it. The company anonymizes all information about visitors to the website (Anonymization) and passes it on to third parties who can use this information or disclose it to other parties who process it as permitted by law. Third parties will not combine your information obtained from our website with information they already have. You can choose to disable cookies of this nature on the company website.`,
        text_topic_nine: `9. Privacy protection policy of other websites`,
        text_nine_desc: `This Privacy Policy is used only for the Company's services. and use of the Company's website only if you have clicked a link to another website (Even through the channels on the company's website), you must study and comply with the privacy policy that appears on that website separate from the company`,
        text_topic_ten: `10. Changes to Personal Data Protection Policy`,
        text_ten_desc: `The Company will regularly review the effectiveness of this Privacy Policy. Such changes to the Policy will be posted or published on the Company's other websites or platforms.`,
        text_topic_eleven: `11. Data Subject Right`,
        text_eleven_detail: `As a personal data subject You have the right to take actions within the scope of the Personal Data Protection Act B.E. 2562 as follows:`,
        text_eleven_desc: [
            `1. You have the right to withdraw consent (Right to withdraw Consent). In processing your personal data, you have given consent to the company throughout the period that the company collects your personal data.`,
            `2. You have the right to access your personal data (Right of Access) and request the Company to make a copy of such personal data for you.`,
            `3. You have the right to ask the Company to correct your personal data. or adding incomplete information (Right to Rectification)`,
            `4. You have the right to request the Company to delete your data for certain reasons (Right to Erasure).`,
            `5. You have the right to request the company to suspend the use of your personal data for certain reasons (Right to Restriction of Processing).`,
            `6. You have the right to transfer personal data that you have provided to the Company to other data controllers or yourself for certain reasons (Right to Data Portability).`,
            `7. You have the right to object to the processing of your data for certain reasons (Right to Object).`,
            `8. You have the right to claim compensation in the event that the breach of personal data causes damage.
            in exercising such rights You can contact the Company as a personal data controller at the address below in order to submit a request for the execution of the above rights. And the company will process your request within 30 days. The company will consider your request within the scope of the Personal Data Protection Act B.E. 2562 and in the event that the company rejects your request. The Company will also provide a reason for refusing your request.`,
        ],
        text_topic_twelve: `12. Contact channels with the company`,
        text_twelve_content: [
            `Pinvestment.Co., Ltd`,
            `Email : vip29@pinvestment.co.th`,
            `Address : No. 200, Moo. 4, Chaeng Watthana Road, Pak Kret Subdistrict, Pak Kret District Nonthaburi 11120 Thailand`,
        ],
    },
    cn: {
        head_title: '个人资料保护政策',
        top_title: '个人资料保护政策',
        text_one: `Pinvestment.Co.,Ltd或 VIP29。认识到保护您的个人信息的重要性。因此, 制定本个人数据保护政策是为了告知作为数据主体的您, 公司收集和使用您的个人数据的目的。本公司有法律义务在必要的范围内并在法律范围内收集、使用和披露您的个人数据。并且公司确认我们已按照个人数据保护法 BE 2562 的规定来保护您的个人数据。`,
        text_topic_one: {
            title_one: `1.什么是个人数据？`,
            title_one_newline: '个人数据分为两类',
            desc_one: `个人数据（一般）是指可以直接或间接识别在世人员身份的个人数据，例如姓名、地址、电子邮件地址、身份证号码。电话号码`,
            desc_two: `敏感个人数据是需要特殊保护的个人数据。在收集、使用或披露此类个人信息时需要特别谨慎，例如有关种族、民族、政治观点、教条的个人信息、宗教或性行为哲学、犯罪记录、健康信息、残疾工会信息、遗传信息和生物数据或规定的信息由个人资料保护委员会`,
        },
        text_topic_two: {
            title_one: `2. 公司从哪里获取您的个人数据?`,
            desc_one: `2.1 公司直接从您那里收到的个人数据 本公司通过正常渠道直接接收您的个人信息。和在线渠道在以下情况下:`,
            text_dot: [
                `您向公司查询或获取信息.`,
                `您通过网站或应用程序创建帐户来注册.`,
                `您已完成调查问卷或通过电话、电子邮件或通过公司成员联系公司.`,
                `您进入该公司的网站. 公司收集通过cookies (Cookies) 获得的信息. 公司可以提供更好、更快、更安全的服务并保障您的隐私。当您使用服务和/或访问平台时. Cookie 是位于您计算机上的文本文件, 用于存储您的互联网使用或浏览行为的详细日志信息. 你 能 学习 更多的 Cookie 政策中有关 Cookie 的详细信息.`,
            ],
            title_two_two: `2.2 公司从代理机构/第三方 (Third Party) 收到的您的个人信息
            公司可能会从与公司关联的机构/第三方获取您的个人数据, 以便根据指示或代表公司进行个人数据的收集、使用或披露。您的个人信息不会用于除本公司指定或命令的目的之外的用途, 并且本公司已要求机构/第三方按照泰国个人数据保护法的标准保密并保护您的个人数据.`,
        },
        text_topic_three: `3.公司收集的您的个人信息类型
        公司根据需要收集您的个人数据, 以实现公司的目标. 可以分类如下.`,
        text_table: {
            thead: ['信息类型', '细节'],
            tbody: [
                {
                    id: 1,
                    data_type: '1. 联系方式',
                    desc: '例如姓名、身份证号码、性别、年龄、现住址、电话号码、电子邮件',
                },
                {
                    id: 2,
                    data_type: '2. 财务信息',
                    desc: `例如有关支付费用或服务费的信息、文件、证据和详细信息、付款历史记录和付款证据, 以及有关银行账户的信息.`,
                },
                {
                    id: 3,
                    data_type: '3. 通讯数据',
                    desc: '如交易信息记录与员工的对话、电子邮件通讯信息',
                },
                {
                    id: 4,
                    data_type: '4. 技术数据',
                    desc: `例如计算机的IP地址浏览器类型时区设置、位置、操作系统平台以及用于访问网站的设备的技术`,
                },
                {
                    id: 5,
                    data_type: '5. 网站使用信息',
                    desc: `网站使用数据包括您进入平台之前访问的网站页面、您访问的网站页面您在平台上浏览网站页面、产品、服务或搜索信息所花费的时间访问网站的时间和日期网站点击数据`,
                },
            ],
        },
        text_topic_four: '4. 使用您个人数据的目的',
        text_detail_four: `我们在数据保护法的范围内使用您的个人数据，并且仅在必要时收集信息:`,
        text_dot_four: [
            {
                id: 1,
                title: `为了公司的合法利益 (Legacy Interest) 或第三方的合法利益。或您的合法利益, 而不损害您对个人数据的基本权利`,
            },
            {
                id: 2,
                title: `用于履行您作为一方的合同或用于在签订合同之前处理您的请求`,
            },
            {
                id: 3,
                title: `遵守公司法律或防止或抑制对人的生命、身体或健康的危险。`,
            },
        ],
        text_desc_four: `公司在下表中总结了其用于该目的的合法处理基础`,
        text_table_four: {
            text_head: ['客观的', '信息类型', '处理基本个人信息'],
            text_row: [
                {
                    id: 1,
                    data_type: {
                        normal_text: `1)根据与客户的合同提供服务`,
                        text_dot: [],
                    },
                    desc: ['联系信息', '财务信息'],
                    process: [`签订服务合同的请求的必要性`],
                },
                {
                    id: 1,
                    data_type: {
                        normal_text: `2)提供客户服务, 包括`,
                        text_dot: [
                            `客户与客户的联系以满足客户的要求`,
                            `提供售后服务 `,
                            '管理客户利益',
                            '接收客户投诉 ',
                            '获得通知并协调解决客户使用问题.',
                            '方便客户验证信息、付款和管理福利. ',
                        ],
                    },
                    desc: ['- 联系信息', '- 财务信息', '- 通讯数据'],
                    process: [
                        `1) 履行服务合同所必需的`,
                        `2) 为了公司提供客户服务的合法利益, 方便客户获得服务.`,
                    ],
                },
                {
                    id: 1,
                    data_type: {
                        normal_text: `3)分析有助于改善公司服务的信息:`,
                        text_dot: [
                            '统计客户数量, 进行营销策划.',
                            '网站开发中解决网站使用过程中出现的技术问题, 改善客户接受公司提供的服务的体验.',
                        ],
                    },
                    desc: ['- 联系信息', '- 技术信息', '- 网站使用信息'],
                    process: [
                        `为了公司使用信息和网站使用统计数据为客户开发服务并改进网站以满足客户需求的合法利益`,
                    ],
                },
                {
                    id: 1,
                    data_type: {
                        normal_text: `4)发送促销消息包括参与营销活动`,
                        text_dot: [],
                    },
                    desc: ['- 联系信息'],
                    process: [
                        `1) 向客户展示新闻和促销活动符合我们的合法利益`,
                        `2) 如果是直接营销, 我们将请求您明确同意我们使用您的个人数据进行直接营销`,
                    ],
                },
            ],
        },
        text_topic_five: `5. 营销活动及营销推广`,
        text_topic_five_detail: `公司可以开展营销和促销活动或聘请与公司达成协议的第三方. 在以下情况下`,
        text_dot_five: [
            `如果公司使用您的个人数据向您进行直接营销 (Direct Marketing) ,只有在您明确同意并且公司已告知您使用您的数据进行直接营销的目的的情况下, 公司才可以这样做. 您可以随时通过选择退出系统撤销您对公司进行直接营销的同意. 公司信息`,
            `向您进行直接营销 公司使用您的信息来分析行为, 以便为您提供适合您需求的服务.`,
            `或各种社交网络上的活动以向广大公众推广公司的产品和服务的情况下. 除非您明确同意公司使用您的个人数据进行直接营销, 否则公司不会处理您的个人数据用于直接营销. 公司将告知您使用您的数据进行直接营销的目的. 您可以随时通过公司的退出系统撤销您对直接营销的同意.`,
        ],
        text_topic_six: '6. 个人信息的披露或共享',
        text_six_one: `6.1 本公司不会向机构/第三方透露您的个人信息. 除非为了实现与运营相关的目标而必须向第三方/第三方披露的情况, 或者是有法律义务要求公司披露信息的机构, 这些实体/方包括:`,
        text_six_one_dot: [
            `附属公司`,
            `在线广告平台`,
            `营销公司或顾问`,
            `个人数据保护委员会办公室等监管机构`,
            `公司聘请的收集数据的部门`,
        ],
        text_six_two: `6.2 本公司要求本公司上述披露或分享您的个人资料的机构/第三方按照泰国个人资料保护法规定的标准予以保密并保护您的个人资料, 并且只能将您的个人资料用于以下目的: 公司指定的目的或该第三方指示的目的第三方将无法将您的个人信息用于其他目的.`,
        text_six_three: `6.3  根据要求, 公司可能会向相关机构披露您的个人信息, 以遵守法律规定. 实现某个目标, 例如防止生命或身体受到威胁. 出于执法目的, 提出指控, 行使合法权利. 以及防止欺诈等.`,
        text_topic_seven: '7. 个人资料的保留及保存期限',
        text_seven_one: `1. 本公司将您的个人信息存储于本公司信息系统中. 并为公司的信息系统提供符合标准的安全性, 并通过以下措施确保您的个人信息的使用安全`,
        text_dot_seven: [
            `限制对官员或员工可能访问的个人信息的访问.`,
            `提供技术手段来防止对计算机系统的未经授权的访问.`,
            `当公司不再需要存储您的个人信息时, 出于安全目的, 设法销毁您的个人信息.`,
            `有一个处理个人数据泄露案件的流程. 或者在怀疑个人数据泄露事件的情况下, 必须根据法律要求的条件向个人数据保护委员会办公室报告. `,
        ],
        text_seven_two: `2. 个人资料的保存期限`,
        text_dot_seven_nd: [
            `公司在实现公司目标所需的期限内以及按照会计准则等公认标准规定的期限保留您的个人数据. 法律标准及其他相关规定`,
            `在确定数据保留期限时, 公司将考虑个人数据的数量、服务的使用目的、个人数据的敏感性以及滥用个人信息的潜在风险以及相关法律规定的期限. `,
            `当您访问网站时收集信息时通过cookies (Cookies) 获得的您的个人信息, 本公司将保存该信息不超过13个月或根据相关法律的要求. `,
        ],
        text_topic_eight: `8. Cookie 政策`,
        text_eight_one: `1. 用于网站运营的 Cookie (功能性 Cookie) :
        公司使用 cookie 来改进网站的功能 (功能性 Cookie) 来收集有关您网站访问的信息以帮助公司。可以提供更好、更快、更安全的服务. 这些类型的 cookie 帮助公司记住您的设备或浏览器, 以便公司可以更快地制作适合您个人兴趣的内容. 使服务和平台更加方便、对您有利. 要禁用这些cookie, 您可以参考浏览器或设备的帮助说明来设置您的设备.`,
        text_eight_two: `2. 分析cookie:
        公司使用第三方提供的分析 Cookie 来收集有关公司网站访问者如何使用网站的信息. 公司对有关网站访问者的所有信息进行匿名处理（匿名化）, 并将其传递给可以使用该信息的第三方, 或将其披露给在法律允许的情况下处理该信息的其他方. 第三方不会将从我们网站获得的您的信息与他们已有的信息结合起来. 您可以选择在公司网站上禁用这种性质的cookie.`,
        text_topic_nine: `9. 其他网站的隐私保护政策`,
        text_nine_desc: `本隐私政策仅用于本公司的服务. 仅当您点击其他网站的链接（即使通过公司网站上的渠道）才使用公司网站时, 您必须研究并遵守该公司网站上显示的隐私政策`,
        text_topic_ten: `10. 个人数据保护政策的变更`,
        text_ten_desc: `本公司将定期审查本隐私政策的有效性. 此类政策变更将在公司的其他网站或平台上发布或公布. `,
        text_topic_eleven: `11. 数据主体权利`,
        text_eleven_detail: `作为个人数据主体，您有权在个人数据保护法 BE 2562 的范围内采取如下行动: `,
        text_eleven_desc: [
            `1. 您有权撤回同意 (Right to撤回同意) .在处理您的个人数据时, 您已在公司收集您的个人数据期间给予公司同意. `,
            `2. 您有权查阅您的个人资料 (查阅权) 并要求本公司为您制作该等个人资料的副本. `,
            `3. 您有权要求本公司更正您的个人资料. 或添加不完整的信息 (纠正权)`,
            `4. 您有权因某些原因要求公司删除您的数据 (删除权) .`,
            `5. 您有权因某些原因要求公司暂停使用您的个人数据 (限制处理权) .`,
            `6. 您有权因某些原因将您提供给公司的个人数据转移给其他数据控制者或您自己 (数据可移植权) .`,
            `7. 您有权因某些原因反对处理您的数据 (反对权) .`,
            `8. 因个人信息泄露造成损失的，您有权要求赔偿.
            在行使此类权利时, 您可以通过以下地址联系作为个人数据控制者的公司, 以提交执行上述权利的请求. 公司将在 30 天内处理您的请求. 如果公司拒绝您的请求, 公司将在个人数据保护法 BE 2562 的范围内考虑您的请求. 公司还将提供拒绝您请求的理由. `,
        ],
        text_topic_twelve: `12. 与公司联系渠道`,
        text_twelve_content: [
            `品投资有限公司`,
            `电子邮件 :  vip29@pinvestment.co.th`,
            `地址: No.200 Moo 4, Chaeng Watthana Road, Pak Kret Subdistrict, Pak Kret District Nonthaburi 11120 Thailand`,
        ],
    },
};

export const titleCookiePolicy = {
    th: [],
    en: {
        head_title: 'นโยบายคุกกี้',
        detail_one: `เว็บไซต์นี้ให้บริการโดย บริษัท พินเวสเมนท์ จำกัด (ในที่นี้รวมเรียกว่า “วีไอพี29”หรือ “เรา” ) เว็บไซต์นี้ใช้คุกกี้และเครื่องมืออื่นเพื่อช่วยแยกแยะรูปแบบการใช้งานเว็บไซต์ของท่านจากผู้ใช้งานอื่นๆซึ่งจะช่วยให้ 
        ท่านได้รับประสบการณ์ที่ดีจากการใช้งานเว็บไซต์และช่วย ให้เราสามารถพัฒนาคุณภาพของเว็บไซต์ให้ดียิ่งขึ้นกรณีที่ท่านใช้งานเว็บไซต์นี้ต่อไปถือว่าท่านได้ยินยอมให้เราติดตั้งคุกกี้ไว้ในเครื่องคอมพิวเตอร์ของท่าน`,
        text_cookie_is: `คุกกี้คืออะไร?`,
        text_cookie_detail: `คุกกี้คือข้อความขนาดเล็กที่ประกอบด้วยส่วนของข้อมูลที่ มาจากการดาวน์โหลดที่อาจถูกเก็บบันทึกไว้ในเว็บเบราว์เซอร์ที่ท่านใช้งานหรืออุปกรณ์อื่นๆ ที่ต่อเชื่อมอินเตอร์เน็ต (อาทิ เครื่องคอมพิวเตอร์ สมาร์ทโฟนหรือแท็บเล็ตของท่าน) โดยที่เครื่องเซิร์ฟเวอร์สามารถเรียกดูได้ในภายหลังคล้ายกับหน่วยความจำของ หน้าเว็บการทำงานของคุกกี้ช่วยให้ เรารวบรวมและจัดเก็บข้อมูลการเยี่ยมชมเว็บไซต์ของท่านดังต่อไปนี้โดย อัตโนมัติ`,
        text_cookie_list: [
            `อินเตอร์เน็ตโดเมนและ IP Addressจากจุดที่ท่านเข้า สู่เว็บไซต์`,
            `ประเภทของเบราว์เซอร์ซอฟต์แวร์ ตลอดจนโครงสร้างและระบบการปฏิบัติงานที่ใช้ในการเข้าสู่เว็บไซต์`,
            `วันที่และเวลาที่ท่านเข้าสู่เว็บไซต์`,
            `ที่อยู่ของเว็บไซต์อื่นที่เชื่อมโยงท่านเข้าสู่เว็บไซต์ของเรา และหน้าเว็บที่ท่านเข้าเยี่ยมชมและนำท่านออกจาก เว็บไซต์ของเรารวมถึงเนื้อหาบนหน้าเว็บที่ท่านเยี่ยมชมและระยะเวลาที่ท่านใช้ใน การเยี่ยมชม.`,
        ],
        text_cookie_info: `คำแถลงเกี่ยวกับนโยบายส่วนบุคคลของเราจะกำหนดรายละเอียดทั้งหมดเกี่ยวกับข้อมูลอื่นๆที่เราจัด เก็บและวิธีการที่เรานำข้อมูลส่วนตัวของท่านไปใช้`,
        text_cookie_use_info: `การใช้งานคุกกี้`,
        text_cookie_use: `ประเภทของคุกกี้ที่เราใช้และในการใช้คุกกี้ดังกล่าว ประกอบด้วย แต่ไม่จำกัดเพียง รายการต่อไปนี้
        คุกกี้ประเภทที่มีความจำเป็นอย่างยิ่ง (Strictly Necessary Cookies) : คุกกี้ประเภทนี้มีความสำคัญต่อการทำงาน ของเว็บไซต์ซึ่งรวมถึงคุกกี้ที่ทำให้ท่านสามารถเข้าถึงข้อมูลและใช้งานในเว็บไซต์ของเราได้อย่างปลอดภัยคุกกี้เพื่อ การวิเคราะห์/วัดผลการทำงานของเว็บไซต์ 
        (Analytical/Performance Cookies) : คุกกี้ประเภทนี้จะช่วยให้เราสามารถจดจำและนับจำนวน ผู้เข้าเยี่ยมชมเว็บไซต์ตลอดจนช่วยให้เราทราบถึงพฤติกรรมในการเยี่ยมชมเว็บไซต์เพื่อปรับปรุงการทำงานของ เว็บไซต์ให้มีคุณภาพดีขึ้นและมีความเหมาะสมมากขึ้นอีกทั้งเพื่อรวบรวมข้อมูลทางสถิติเกี่ยวกับวิธีการเข้าและ พฤติกรรมการเยี่ยมชมเว็บไซต์ซึ่งจะช่วยปรับปรุงการทำงานของเว็บไซต์โดยให้ผู้ใช้งานสามารถค้นหาสิ่งที่ต้องการ 
        ได้อย่างง่ายดายและช่วยให้เราเข้าใจถึงความสนใจของผู้ ใช้และวัดความมีประสิทธิผลของโฆษณาของเราคุกกี้ เพื่อการทำงานของเว็บไซต์ (Functionality Cookies) : คุกกี้ประเภทนี้ใช้ในการจดจำตัวท่านเมื่อท่านกลับมาใช้งาน เว็บไซต์อีกครั้งซึ่งจะช่วยให้เราสามารถปรับแต่งเนื้อหาสำหรับท่าน ปรับให้เว็บไซต์ของเราตอบสนองความต้องการ ใช้งานของท่าน  รวมถึงจดจำการตั้งค่าของท่าน อาทิ ภาษาหรือภูมิภาคหรือขนาดของตัวอักษรที่ท่านเลือกใช้ 
        ในการใช้งานในเว็บไซต์คุกกี้เพื่อปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย (Targeting Cookies): คุกกี้ประเภทนี้จะบันทึกการเข้าชมเว็บไซต์ของท่านหน้าเว็บ ที่ท่านได้เยี่ยมชม และลิงค์ที่ท่านเยี่ยมชมเราจะใช้ข้อมูล นี้เพื่อปรับให้เว็บไซต์และเนื้อหาใดๆที่ปรากฏ อยู่บนหน้าเว็บตรงกับความสนใจ ของคุณมากขึ้นนอกจาก นี้เรายังอาจแชร์ข้อมูลนี้กับบุคคลที่สามเพื่อวัตถุประสงค์ ดังกล่าวคุกกี้เพื่อการโฆษณา (Advertising Cookies): คุกกี้ประเภทนี้จะจดจำการตั้งค่าของท่านในการเข้าใช้ 
        งานหน้าเว็บไซต์และนำไปใช้เป็นข้อมูลประกอบการปรับเปลี่ยนหน้าเว็บไซต์เพื่อนำเสนอโฆษณาที่เหมาะสมกับท่านมากที่สุดเท่าที่จะเป็นไปได้ ตัวอย่างเช่นการเลือกแสดงโฆษณา สินค้าที่ท่านสนใจการป้องกันหรือการจำกัดจำนวนครั้งที่ ท่านจะเห็นหน้าเว็บไซต์ของโฆษณาซ้ำๆเพื่อช่วยวัดความมี ประสิทธิผลของโฆษณาโปรดทราบว่าคุกกี้บางประเภทใน เว็บไซต์นี้จัดการโดยบุคคลที่สาม เช่น เครือข่ายการโฆษณา ลักษณะการทำงานต่างๆ อาทิ วิดีโอ แผนที่ และ โซเชียลมีเดียและ ผู้ให้บริการเว็บไซต์ภายนอกอื่น ๆ 
        เช่น บริการวิเคราะห์การเข้าเยี่ยมชมเว็บไซต์เป็นต้นคุกกี้เหล่านี้มัก จะเป็นคุกกี้เพื่อการวิเคราะห์/วัดผลการทำงาน หรือคุกกี้เพื่อปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย ท่านควรต้องศึกษานโยบายการใช้คุกกี้และนโยบายส่วน บุคคลในเว็บไซต์ของบุคคลที่สามเพื่อให้เข้าใจถึง วิธีการ ที่บุคคลที่สามอาจนำข้อมูลของท่านไปใช้`,
        text_cookie_setting: `การตั้งค่าคุกกี้`,
        text_cookie_setting_detail: `ท่านสามารถบล็อกการทำงานของคุกกี้ได้โดยการกำหนดค่าในเบราวเซอร์ของท่านซึ่งท่านอาจ ปฏิเสธการติดตั้งค่าคุกกี้ทั้งหมดหรือบางประเภทก็ได้แต่พึงตระหนักว่าหากท่านตั้งค่าเบราวเซอร์ของท่านด้วยการบล็อกคุกกี้ทั้งหมด(รวมถึงคุกกี้ที่จำเป็นต่อการใช้งาน)ท่านอาจจะไม่สามารเข้าสู่เว็บไซต์ทั้งหมดหรือบางส่วนของเราได้ เมื่อใดก็ตามที่ท่านต้องการยกเลิกความยินยอมการใช้งานคุกกี้ท่านจะต้องตั้งค่าเบราวเซอร์ของท่านเพื่อให้ลบคุกกี้ 
        ออกจากแต่ละเบราวเซอร์ที่ท่านใช้งานหากท่านต้องการข้อมูลเพิ่มเติมเกี่ยวกับวิธีการดังกล่าวโปรดเลือกหัวข้อ“ความช่วยเหลือ”ในอินเตอร์เน็ตเบราวเซอร์ของท่านเพื่อศึกษาในรายละเอียดมากขึ้น`,
    },
    cn: [],
};

export const titleHowToBooking = {
    th: {
        head_title: `วิธีการจองรถกับ Luxury29 Car Rental`,
        head_text_content: `ขั้นตอนการจองรถ`,
        topic_content: [
            {
                title: `1. เตรียมข้อมูลที่ต้องใช้สำหรับการจองรถ`,
                desc: [
                    `อีเมล์ / เบอร์โทรศัพท์ / ชื่อของคุณ / วันที่และเวลารับส่ง / สถานที่รับ-ส่ง`,
                ],
            },
            {
                title: `2. ระบุข้อมูลสถานที่รับ-วันและเวลารับ-จุดหมายปลายทาง`,
                desc: [`หลังจากนั้นกดที่ปุ่ม “จอง”`],
            },
            {
                title: `3. เลือกรถ,อ่านรายละเอียดรถและทำการจอง`,
                desc: [
                    `เลือกประเภทของรถ ตรวจสอบราคาค่าบริการ กรอกรายละเอียดข้อมูลติดต่อ หลังจากนั้นกดปุ่ม “ถัดไป”`,
                    `เลือกช่องทางการเงินมัดจำ /การจ่ายเต็มจำนวน และกดปุ่ม “จองทันที” เพื่อรับเอกสารยืนยัน การจองผ่านทางช่องทางอีเมล์`,
                ],
            },
            {
                title: `4. การจอง-ยืนยันการเช่ารถ`,
                desc: [
                    `การจองรถรถตู้ Alphard ให้ดำเนินการล่วงหน้าอย่างน้อย1-2 วันก่อนวันเดินทาง`,
                    `กรณีรถ Rolls-Royce,Bentley Flying Spurs,Bentley Bentayga, Benz Maybach S600,Benz Saloon S500E,Audi Q7 ให้ทำการจองล่วงหน้า 3 วัน ก่อนการเดินทาง`,
                    `หลังจาก "จองทันที" และโอนเงินมัดจำเรียบร้อย ท่านจะได้รับหมายเลขการจอง และอีเมล์ตอบรับ จากระบบถือว่า การจองของท่านได้เสร็จสมบูรณ`,
                    `ก่อนล่วงหน้า 1 วัน ทางเจ้าหน้าที่จะติดต่อกลับ เพื่อ”คอนเฟิร์ม”รายละเอียด คนขับและทะเบียนรถ และท่านจะได้รับข้อความและอีเมลรายละเอียดดังกล่าว`,
                ],
            },
        ],
    },
    en: {
        head_title: `Start Booking Your Car Rental and Driver Service with Luxury29 Car Rental`,
        head_text_content: `Getting Start:`,
        topic_content: [
            {
                title: `1. Prepare the information needed`,
                desc: [
                    `E-mail / Phone Number / Your Full Name / Pickup/Drop-off Date and time / Pickup/Drop-off Destination`,
                ],
            },
            {
                title: `2. Provide pickup and drop off information: date, time, location`,
                desc: [`hen proceed to click “Book Now” button`],
            },
            {
                title: `3. Choose your car, learn more about the car model's information, and confirm the booking`,
                desc: [
                    `Choose from the car models available, check the service fee, put required information, the click “Next”`,
                    `Service fee and service policy are subjected to be varied due to the car's model.`,
                    'Select your payment method, amount of the deposit (50%/full price), then proceed to “Book Now” and receive your booking confirmation via your provided e-mail.',
                ],
            },
            {
                title: `4. Reservation-Confirmation of the car rental with driver service`,
                desc: [
                    `For Toyota Alphard model, the reservation must be made at least 1-2 days in advance.`,
                    `For Rolls-Royce, Bentley Flying Spurs, Bentley Bentayga, Benz Maybach S600, Benz Saloon S500E, Audi Q7, the reservation must be made at least 3 days in advance.`,
                    `After clicking “Book Now” and made the deposit, you will receive booking number and confirmation e-mail from the system.`,
                    `One day prior to the pickup date, a staff will be in contact to confirm your booking details, car and driver's information. All information will also be sent to the e-mail provided during the reservation.`,
                ],
            },
        ],
    },
    cn: {
        head_title: `开始使用 Luxury29 Car Rental 预订汽车租赁和司机服务`,
        head_text_content: `一开始:`,
        topic_content: [
            {
                title: `1. 准备所需资料`,
                desc: [
                    `电子邮件 / 电话/手机号码 / 您的全名 / 接送日期和时间 / 接送地点`,
                ],
            },
            {
                title: `2. 提供接送信息：日期、时间、地点`,
                desc: [`继续点击 《立即预订》 按钮`],
            },
            {
                title: `3. 选择您的车辆，了解更多信息并确认您的预订`,
                desc: [
                    `选择可用车型，查看服务费用，填写所需信息，然后点击 《下一步》`,
                    `价格和服务政策因型号而异。`,
                    '选择您的付款方式、存款金额 (50%/全价），继续点击 《立即预订》并通过您提供的电子邮件接收预订确认。',
                ],
            },
            {
                title: `4. 预订-确认租车并提供司机服务`,
                desc: [
                    `丰田阿尔法德车型需至少提前1-2天预订。`,
                    `劳斯莱斯、宾利飞驰、宾利添越、奔驰迈巴赫S600、奔驰轿车S500E、奥迪Q7需至少提前3天预订。`,
                    `点击《立即预订》并支付押金后，您将收到系统发送的预订号码和确认电子邮件。`,
                    `工作人员将在接载日期前一天与您联系，以确认您的预订详细信息、车辆和司机信息。 所有信息也将发送至预订时提供的电子邮件。`,
                ],
            },
        ],
    },
};

export const textPayment = {
    th: {
        head_title: 'การชำระเงิน',
        line_one: ` วิธีการชำระเงินของ Luxury29 Car Rental มีหลากหลายช่องทางด้วยกัน สะดวก รวดเร็ว ปลอดภัย ทั้ง บัตรเครดิต และพร้อมเพย์`,
        line_two: `คุณสามารถเลือกชำระค่าสินค้าและบริการด้วยบัตรเครดิตที่มีตราสัญลักษณ์ [VISA], [Mastercard],และ [JCB] ทั่วประเทศ และทั่วโลก และสั่งซื้อสินค้าได้ง่ายๆ ผ่านทางหน้าเว็บไซต์`,
        line_three: `คุณสามารถเลือกชำระค่าสินค้าและบริการโดยการโอนเงินผ่านผ่านพร้อมเพย์`,
        cc: 'ชำระผ่านบัตรเครดิต',
        pp: `ชำระโดยการโอนผ่านพร้อมเพย์`,
    },
    en: {
        head_title: 'Payment',
        line_one: `There are many payment methods to pay at Luxury29 Car Rental through a variety of channels such as credit cards , PromptPay and Alipay which are convenient, fast and safe.`,
        line_two: `You can choose to pay for services with credit cards bearing the [VISA], [Mastercard] ,and
        [JCB] logos nationwide and worldwide, and make purchases easily. through the website`,
        line_three: `You can choose to pay for goods and services by transferring funds via PromptPay.`,
        cc: 'Credit card',
        pp: `PromptPay`,
    },
    cn: {
        head_title: 'Payment',
        line_one: `There are many payment methods to pay at Luxury29 Car Rental through a variety of channels such as credit cards , PromptPay and Alipay which are convenient, fast and safe.`,
        line_two: `You can choose to pay for services with credit cards bearing the [VISA], [Mastercard] ,and
        [JCB] logos nationwide and worldwide, and make purchases easily. through the website`,
        line_three: `You can choose to pay for goods and services by transferring funds via PromptPay.`,
        cc: 'Credit card',
        pp: `PromptPay`,
    },
};

export const textCancell = {
    th: {
        head_title: 'นโยบายการยกเลิก',
        text_one: `นโยบายการยกเลิกและการคืนเงิน ขอสงวนสิทธิ์การคืนเงินในทุกกรณี อย่างไรก็ตามคุณสามารถเลื่อนวันเดินทางได้ภายใน 30 วัน การจองที่ได้รับการยืนยันคือเมื่อเราได้แจ้งให้คุณทราบว่าคำขอบริการนี้พร้อมให้บริการและได้ทำการจองไว้แล้ว`,
        text_two: `ทั้งนี้การยกเลิกการเดินทางหลังจากที่ได้ชำระเงินมัดจำ การจองแล้ว ท่านจะไม่มีสิทธิได้รับเงินคืน โดยหากท่านยกเลิกการใช้บริการก่อนการเดินทางทางบริษัทตกลงปรับเปลี่ยนวันเดินทางให้กับท่าน แต่หากยกเลิกน้อย กว่า 1 วัน ก่อนการเดินทางทางบริษัทขอสงวนสิทธ์ไม่คืนเงินมัดจำค่าบริการไม่ว่า กรณีใดๆ ทั้งสิ้น`,
        specification: 'ข้อกำหนด',
        text_table: [
            {
                text_one: 'มากกว่า 1 วัน ก่อนการเดินทาง',
                text_two: 'เปลี่ยนแปลงวันเดินทางได้/ไม่คืนเงิน',
            },
            {
                text_one: 'น้อยกว่า 24 ชั่วโมง ก่อนวันเดินทาง',
                text_two: 'ไม่คืนเงิน',
            },
            {
                text_one: 'ยกเลิกหน้างาน ( NO-Show)',
                text_two: 'ไม่คืนเงิน',
            },
        ],
        text_three: `ขอบคุณที่ให้ความสำคัญกับนโยบายการยกเลิกของเรา หากมีข้อสงสัยเพิ่มเติมหรือต้องการขอความช่วยเหลือเพิ่มเติม กรุณาติดต่อเราผ่านช่องทาง Line ID: @lux29car หรือส่งอีเมลมาที่ vip29@pinvestment.co.th และในอีเมลกรุณาระบุรายละเอียดต่อไปนี้:`,
        text_detail: [
            `หมายเลขอ้างอิงการจอง`,
            `ที่อยู่อีเมลที่ใช้ในการจอง`,
            `ชื่อและนามสกุลที่ใช้ในการจอง`,
            `วันที่ที่คุณต้องการยกเลิกหรือเลื่อนวันเดินทาง`,
        ],
        text_more:
            'หากมีข้อมูลหรือการเปลี่ยนแปลงใดๆ ที่คุณต้องการแจ้งเพิ่มเติม กรุณาติดต่อเราทางช่องทาง',
        text_social_contact: {
            line: 'Line ID: https://line.me/ti/p/f',
            email: 'E-mail: vip29@pinvest.co.th',
            call: 'Call Center: 064-642-4915',
        },
    },
    en: {
        head_title: 'Cancellation Policy',
        text_one: `Luxury29 Car Rental reserves all right on NO CANCELLATION/NO REFUND once the reservation has been settled. However, we are more than happy to accommodate you on your adjusted itinerary within 30 days period. The reservation is confirmed when your itinerary has been discussed and the deposit has been made.`,
        text_two: `The amendment in your itinerary must be made 1 day prior to the date reserved. A shorter notice would be considered cancellation with no refund.`,
        specification: 'Condition',
        text_table: [
            {
                text_one: '>1 Day (s) prior to arrival',
                text_two: 'Amendment /Non Refund',
            },
            {
                text_one: '<24 hours prior to arrival',
                text_two: 'Non Refund',
            },
            {
                text_one: 'NO-Show',
                text_two: 'Non Refund',
            },
        ],
        text_three: `To cancel a reservation, send message via Line ID: @lux29car or email vip29@pinvestment.co.th with 'Cancel' in the subject of the email. The details that must be included in the itinerary amendment message:`,
        text_detail: [
            `Booking reference ID number`,
            `Email address used in the reservation`,
            `First and last name in the reservation`,
            `Booking Date`,
        ],
        text_more:
            'For more queries or additional remarks, please feel at ease to contact:',
        text_social_contact: {
            line: 'Line ID: https://line.me/ti/p/f',
            email: 'E-mail: vip29@pinvest.co.th',
            call: 'Call Center: 064-642-4915',
        },
    },
    cn: {
        head_title: '取消政策',
        text_one: `预订一经确定, Luxury29 Car Rental 保留不取消/不退款的所有权利。 不过，我们非常乐意在 30 天内为您调整行程。 当您讨论了行程并支付了押金后，预订即被确认。`,
        text_two: `行程的修改必须在预订日期前 1 天进行。 较短的通知将被视为取消且不予退款。`,
        specification: '条件',
        text_table: [
            {
                text_one: '至少在抵达前 1 天通知',
                text_two: '修改/无退款',
            },
            {
                text_one: '抵达前 24 小时内通知',
                text_two: '无退款',
            },
            {
                text_one: '缺席',
                text_two: '无退款',
            },
        ],
        text_three: `若需取消预订，请通过直线联系我们：@lux29car 或发送电子邮件至 vip29@pinvestment.co.th, 并在电子邮件主题中注明“取消”。 行程修改中必须包含的详细信息`,
        text_detail: [
            `1. 预订参考 ID 号`,
            `2. 预订时使用的电子邮件地址`,
            `3. 预订时的名字和姓氏`,
            `4. 预订日期`,
        ],
        text_more: '如有更多疑问或补充意见，请随时联系',
        text_social_contact: {
            line: 'Line ID: https://line.me/ti/p/f',
            email: '电子邮件: vip29@pinvest.co.th',
            call: '呼叫中心: 064-642-4915',
        },
    },
};

export const textTermsCondition = {
    th: {
        head_title: 'ข้อตกลง และเงื่อนไขการให้บริการเช่ารถยนต์พร้อมคนขับ',
        one_title: `ข้อ 1. ข้อตกลงและเงื่อนไขการใช้บริการ`,
        one_dot_text: `บริษัทพินเวสเม้นท์ จำกัด (“บริษัท”) ตกลงให้บริการ และผู้บริการตกลงเช่ารถยนต์ พร้อมกับบริการคนขับรถ 
        เพื่อเดินทางไปยังสถานที่ต่างๆ (ซึ่งไปต่อนี้จะเรียกว่า "รถเช่า") ตามเงื่อนไข และข้อตกลงฉบับนี้ (ซึ่งไปต่อนี้จะเรียกว่า "ข้อตกลง") 
        ซึ่งผู้เช่าตกลงยินยอมปฏิบัติตามข้อตกลงและเงื่อนไขนี้ทุกประการ`,
        two_title: `ข้อ 2. เงื่อนไขการให้บริการ`,
        two_one_title: `2.1	จำนวนผู้โดยสารให้เป็นไปตามข้อกำหนดของแต่ละรุ่นรถที่เลือกใช้บริการ`,
        two_two_title: `2.2	ราคาค่าบริการเช่ารถตู้พร้อมบริการคนขับ ให้เป็นไปตามเงื่อนไข และข้อกำหนดการให้บริการของรถแต่รุ่น โดยจะไม่รวมค่าน้ำมัน, ค่าทางด่วน, ค่าจอดรถและค่าใช้จ่ายอื่นๆที่เกี่ยวกับการเดินทาง(รถเช่าทุกคันให้บริการด้วยน้ำมันเต็มถังผู้เช่าต้องคืนรถพร้อมน้ำมันที่เต็มถัง ในกรณีที่ไม่เต็มถัง ทางบริษัทฯคิดค่าบริการตามเงื่อนไขที่กำหนด)`,
        two_three_title: `2.3 ระยะเวลาให้เช่าไม่เกิน 10 ชั่วโมงต่อวันทั้งนี้ตามเงื่อนไขการให้บริการของรถเช่าแต่ละรุ่นไม่ว่าจะเริ่มการเช่าเวลาใด การให้บริการจะต้องไม่เกินเวลา 23.59 น. ของวันนั้นหากเกินหลังเวลา 00.00 น. จะทำการคิดค่าบริการเพิ่ม 1 วัน`,
        two_dot_text: `กรณีเริ่มเช่า ในช่วงเวลา 01.00-07.00 น. ลูกค้าต้องจองล่วงหน้าไม่น้อยกว่า 3 วันก่อนเวลาเช่า`,
        two_four_title: `2.4 อัตราค่าเช่าล่วงเวลา`,
        three_dot_text: `กรณีเกินระยะเวลาเช่า ตามข้อ 2.3 คิดค่าเช่าล่วงเวลา ดังนี้`,
        text_table: [
            {
                one_desc: `รุ่นรถเช่า`,
                two_desc: `ชั่วโมงการให้เช่า`,
                three_desc: `ค่าเช่าล่วงเวลา`,
                four_desc: `อัตราค่าล่วงเวลา/ชั่วโมง`,
            },
            {
                one_desc: `Alphard 2024`,
                two_desc: `ไม่เกิน 4 ชั่วโมง`,
                three_desc: `ไม่เกิน 2 ชั่วโมง`,
                four_desc: `1,000.-บาท`,
            },
            {
                one_desc: `Alphard 2024`,
                two_desc: `ไม่เกิน 10 ชั่วโมง`,
                three_desc: `ไม่เกิน 2 ชั่วโมง`,
                four_desc: `500.-บาท`,
            },
            {
                one_desc: `**รถหรูรุ่นอื่นๆ`,
                two_desc: `ไม่เกิน 10 ชั่วโมง`,
                three_desc: `-`,
                four_desc: `กรณีเช่าเกินระยะเวลาเช่าที่กำหนดคิดค่าเช่าเพิ่มเติมเท่ากับ1 วัน`,
            },
        ],
        note: `หมายเหตุ : **รถหรูอื่นๆ ได้แก่ รถยนต์Rolls-Royce,Bentley Flying Spurs,Bentley Bentayga, Benz Maybach S600,Benz Saloon S500E และ Audi Q7`,
        text_five_title: [
            {
                normal_text: `2.5 กรณีเดินทางไปต่างจังหวัด และค้างคืนและทางผู้เช่าไม่มีที่พักให้กับพนักงานขับรถทางบริษัทจะเรียกเก็บค่าที่พักสำหรับพนักงานขับรถเพิ่มเติมจากค่าบริการ เป็นเงินจำนวน 500 บาท/ต่อคืน`,
            },
            {
                note_text: `2.6 ความเร็วสูงสุด ไม่เกิน 100 km/ชั่วโมง`,
            },
            {
                normal_text: `2.7 จอดพักรถไม่น้อยกว่า 30 นาที ทุกๆ4 ชั่วโมง`,
            },
            {
                normal_text: `2.8 ห้ามนำสิ่งผิดกฎหมาย หรือสัตว์เลี้ยง ขึ้นบนรถเช่า`,
            },
            {
                normal_text: `2.9 ห้ามนำวัตถุไวไฟ หรือวัตถุระเบิดขึ้นรถ`,
            },
            {
                normal_text: `2.10 ห้ามสูบบุหรี่ หรือของสุรา หรือยาเสพติดบนรถเช่า`,
            },
            {
                normal_text: `2.11 ห้ามนำสิ่งของ อาหาร ที่มีกลิ่นฉุนรุนแรง ขึ้นบนรถเช่า`,
            },
            {
                normal_text: `2.12 ห้ามทำทรัพย์สิน หรืออุปกรณ์ใดๆ ภายในรถเช่าเสียหาย หรือสูญหายหากมีความเสียหาย เกิดขึ้นผู้เช่าจะต้องรับผิดชอบชดใช้ค่าเสียหายให้แก่บริษัทภายในวันที่ส่งมอบเช่าคืนหรือวันที่สิ้น สุดการให้บริการ`,
            },
            {
                normal_text:
                    '2.13 การใช้รถเพื่อวัถตุประสงค์ในการเดินทางและการท่องเที่ยวเท่านั้นห้ามนำรถเช่าไปใช้ประโยชน์ ในเชิงพาณิชย์ หรือนำไปทำสิ่งผิดกฎหมายใดๆทั้งสิ้น',
            },
            {
                normal_text:
                    '2.14 ห้ามผู้เช่า ผู้โดยสาร  หรือบุคคลที่เกี่ยวข้องถ่ายภาพ หรือบันทึกภาพทั้งภายในและภายนอก รถเช่า พนักงาน ไปโพสต์ลงโซเชียลเน็ตเวิร์คต่างๆ  รวมถึงจะไม่นำไปเผยแพร่ในช่องทางใดๆ เว้นแต่จะได้รับความยินยอมจากบริษัทก่อน',
            },
            {
                normal_text:
                    '2.15 ห้ามผู้เช่านำรถเช่าไปให้เช่าช่วง หรือให้บริการใดๆรวมทั้งจะไม่ให้บุคคลที่ไม่ใช่พนักงานของผู้เช่า ใช้รถเช่า หากผู้เช่ากระทำการดังกล่าวผู้เช่าตกลงจะเป็นผู้รับผิดชอบค่าเสียหายที่เกิดขึ้นทั้งหมด',
            },
        ],
        title_text_three: `ข้อ 3. การรักษาความลับ`,
        desc_text_three: `บรรดาเอกสารหรือข้อมูลใดๆ ที่เกี่ยวข้องกับการให้บริการตามข้อตกลงนี้ ที่ระบุไว้โดยชัดแจ้งว่าลับ หรือห้ามเปิดเผยหรือข้อความอื่นใดในทำนองเดียวกัน เป็นข้อมูลอันเป็นความลับ ห้ามผู้เช่า และ/หรือบริวารของผู้เช่าเปิดเผย หรือเผยแพร่โดยเด็ดขาด 
        เว้นแต่เป็นการเปิดเผย ตามกฎหมาย โดยคำสั่งศาลหรือองค์กรที่มีหน้าที่กำกับดูแล หรือ เป็นการเปิดเผยต่อพนักงาน และ/หรือ ที่ปรึกษาของแต่ละฝ่าย หรือได้รับความยินยอมเป็นลายลักษณ์อักษรจากเจ้าของข้อมูล`,
        title_text_four: `ข้อ 4. การโอนสิทธิ`,
        desc_text_four:
            'ผู้เช่าตกลงจะไม่โอนสิทธิและหน้าที่ใด ๆ ตามข้อตกลงนี้ไม่ว่าทั้งหมดหรือบางส่วนให้แก่บุคคลอื่น เว้นแต่จะได้รับความยินยอมเป็นลายลักษณ์อักษรจากบริษัทก่อน อย่างไรก็ตามบริษัทมีสิทธิโอนสิทธิ และหน้าที่ใด ๆ ตามข้อตกลงนี้ไม่ว่าทั้งหมดหรือบางส่วนให้แก่บุคคลอื่นได้ โดยไม่ต้องรับความยินยอมเป็นลายลักษณ์อักษรจากผู้เช่าก่อน',
        title_text_five: 'การยกเลิก/การคืนเงิน',
        text_dot_five: [
            `5.1 มัดจำค่าบริการรถเช่าล่วงหน้า 50% ของยอดค่าเช่าทั้งหมด และชำระส่วนที่เหลือ และค่าบริการอื่นๆ ในวันที่สิ้นสุดการให้บริการ ผู้เช่าต้องชำระเงินมัดจำในวันที่ได้ยืนยันการจอง โดยการโอนเงินเข้าบัญชีของบริษัท ผ่านช่องทางออนไลน์ หรือ Payment Gateway ตามที่บริษัทกำหนด`,
            '5.2 ในกรณีผู้เช่ายกเลิกการเช่า หลังจากที่ได้ชำระเงินมัดจำการจองเรียบร้อยแล้ว ผู้เช่าจะไม่มีสิทธิได้รับเงินค่ามัดจำคืนไม่ว่ากรณีใดๆ โดยหากผู้เช่ายกเลิกการใช้บริการ ก่อนการเดินทาง ล่วงหน้าเป็นเวลาไม่น้อยกว่า 1 วัน บริษัทตกลงจะปรับเปลี่ยนวันเดินทางให้กับท่าน ตามวัน และเวลาที่กำหนดร่วมกัน แต่หากท่านยกเลิกการเดินน้อย กว่า 1 วัน ก่อนการเดินทาง บริษัทขอสงวนสิทธ์ไม่คืนเงินมัดจำค่าเช่าไม่ว่ากรณีใดๆ ทั้งสิ้น โดยให้ถือเป็นค่าเสียหาย และค่าสูญเสียโอกาสของบริษัท',
            `5.3 การยกเลิก หรือลดจำนวนวันระหว่างการใช้งานทางบริษัทขอคิดค่าเช่ารถเต็มจำนวน ตามที่ได้ตกลงกันไว้`,
        ],
        title_text_six: 'ข้อ 6. การสิ้นสุดการเช่า',
        text_dot_six: [
            '6.1 คู่สัญญาตกลงกันว่า ข้อความในข้อตกลงนี้ถือเป็นสาระสำคัญทุกข้อ  หากผู้เช่าผิดนัดไม่ชำระค่าเช่าหรือเงินประกัน หรือค่าบริการใด ๆ หรือประพฤติผิดข้อตกลงนี้ ซึ่งถือเป็นส่วนหนึ่งของข้อตกลงนี้ด้วย ไม่ว่าข้อหนึ่งข้อใดก็ดี บริษัทมีสิทธิบอกเลิกข้อตกลงนี้ หรือระงับการให้บริการ หรือยกเลิกการบริการได้ทันที และมีสิทธิเรียกร้องค่าเสียหาย ริบเงินมัดจำ เงินประกัน และ/หรือเงินอื่นใดที่ผู้เช่าได้ชำระ หรือวางไว้ให้แก่ผู้ให้เช่าตามสัญญานี้ โดยผู้เช่าจะเรียกร้องค่าเสียหาย หรือค่าชดเชยใด ๆ จากบริษัทไม่ได้ทั้งสิ้น',
            '6.2 ในกรณีที่ผู้เช่าผิดนัดชำระเงินใดๆ ที่จะต้องชำระตามข้อตกลงนี้ แต่บริษัทยังไม่ได้ใช้สิทธิบอกเลิกข้อตกลง ผู้เช่าจะต้องเสียค่าปรับให้แก่ผู้ให้เช่าในอัตราร้อยละ 15(สิบห้า) ต่อปี ของเงินที่ค้างชำระนับแต่วันที่ผิดนัดเป็นต้นไปจนกว่าจะชำระให้ครบถ้วน รวมทั้งจะต้องชำระค่าใช้จ่ายในการติดตามทวงถามเงินที่ค้างชำระอีกร้อยละ 4(สี่) ต่อปีของเงินที่ค้างชำระ แต่ทั้งนี้ไม่เป็นการตัดสิทธิบริษัทที่จะบอกเลิกข้อตกลง หรือดำเนินการอื่นๆ ตามสิทธิของบริษัท ที่มีอยู่ตามข้อตกลงนี้',
            `6.3 ในกรณีที่บริษัทมีข้อผูกพันต้องชำระเงินให้แก่ผู้เช่าภายใต้ข้อตกลงและเงื่อนไขนี้ บริษัทอาจหักลบกลบหนี้เงินจำนวนนั้นกับเงินที่ผู้เช่าต้องชำระให้บริษัทได้ไม่ว่ากรณีใดๆ ทั้งสิ้น`,
            `6.4 การกระทำใด ๆ ที่เป็นสิทธิของบริษัทตามที่ระบุไว้ในข้อตกลงนี้ เมื่อบริษัทและตัวแทนได้ดำเนินการสิ่งหนึ่งสิ่งใดไปแล้ว ให้ถือว่าการกระทำดังกล่าวนั้น ผู้เช่าได้ยินยอมด้วยทุกประการ และผู้เช่าจะไม่ยกขึ้นเป็นข้ออ้างเพื่อเรียกค่าเสียหายใดๆ หรือจะฟ้องร้องทั้งในทางแพ่งและทางอาญาต่อบริษัทและตัวแทนไม่ได้ทั้งสิ้น`,
        ],
        title_text_seven: 'ข้อ 7. ข้อตกลงอื่นๆ',
        text_dot_seven: [
            `7.1 ในกรณีที่ปรากฏภายหลังว่าส่วนใดส่วนหนึ่งของข้อตกลงนี้ไม่ชอบด้วยกฏหมาย ไม่สมบูรณ์ ตกเป็นโมฆะ หรือไม่อาจใช้บังคับได้ตามกฏหมายที่ใช้บังคับ ให้เงื่อนไขอื่นที่เหลือในข้อตกลงนี้ยังคงมีผลสมบูรณ์ ถูกต้องตามกฏหมายและสามารถใช้บังคับได้อย่างสมบูรณ์ต่อไป โดยทุกฝ่ายตกลงให้บทบัญญัติที่เหลือของข้อตกลงยังคงมีผลผูกพันและใช้บังคับได้ในระหว่างกัน`,
            `7.2 บริษัทสามารถเปลี่ยนข้อตกลงและเงื่อนไขเหล่านี้ได้ ในกรณีที่มีการเปลี่ยนแปลงข้อตกลงและเงื่อนไข บริษัทจะแจ้งให้ทราบถึงเนื้อความการเปลี่ยนแปลงข้อตกลงและเงื่อนไข เนื้อหาของข้อตกลงและเงื่อนไขหลังการเปลี่ยนแปลง และช่วงเวลาที่มีผลบังคับใช้โดยวิธีที่เหมาะสม เช่น การโพสต์บนเว็บไซต์ของบริษัท`,
            `7.3 ข้อตกกลงนี้ให้ใช้บังคับและตีความตามกฎหมายไทย`,
        ],
        last_text: `ผู้เช่าได้อ่านและเข้าใจข้อความโดยตลอด แล้วเห็นว่าตรงกับเจตนาของตน และตกลงยอมรับ และปฏิบัติตามข้อตกลงนี้ทุกประการ`,
    },
    en: {
        head_title: 'Chauffeur Service Agreement',
        one_title: `1. Terms and Conditions`,
        one_dot_text: `Pinvestment Company Limited (the “Company”) agrees to provide car rental with driver service (the “Chauffeur Service”) and the “Client” agrees on receiving the Chauffeur Service, the details as agreed upon in this agreement (the “Agreement”).
        The “client” hereby agrees to all terms and conditions in this “agreement”`,
        two_title: `2. Terms of Service`,
        two_one_title: `2.1	The number of passengers shall be in accordance with the selected car's available seats as provided by the “company”.`,
        two_two_title: `2.2	Chauffeur Service fee will be in accordance to the selected car's capabilities. The service fee is non-inclusive of; fuel, toll fees, parking fees, and/or any other expenses occur in relation to the trip. All cars provided in the chauffeur service will provide the client with full tank of fuel. Upon the end of the trip, the client is obliged to return the chauffeur service with full tank of fuel. The failure to return the chauffeur service with a full tank of fuel, the company reserved the right to charge the client the amount short.`,
        two_three_title: `2.3 Day-service period is “10 hours”. The time of service will not exceed 23.59 hr. of the service date. The service needed after 00.00 hr. will be considered as and additional day which the client is obliged to another full day service fee.`,
        two_dot_text: `For the chauffeur service during 01.00-07.00 hr., the client will need to make a reservation 3 days prior to the service date.`,
        two_four_title: `2.4 Extended hour charge`,
        three_dot_text: `In the situation where the chauffeur service hour was to be extended, the extra service fee is as follows:`,
        text_table: [
            {
                one_desc: `Car Model`,
                two_desc: `Time of service`,
                three_desc: `Time Exceeded from the Original Hour`,
                four_desc: `Additional Service Fee per Hour`,
            },
            {
                one_desc: `Alphard 2024`,
                two_desc: `Maximum: 4 hours`,
                three_desc: `No later than 2 hours`,
                four_desc: `1,000 Baht`,
            },
            {
                one_desc: `Alphard 2024`,
                two_desc: `Maximum: 10 hours`,
                three_desc: `No later than 2 hours`,
                four_desc: `500 Baht`,
            },
            {
                one_desc: `** Other luxury cars`,
                two_desc: `Maximum: 10 hours`,
                three_desc: `-`,
                four_desc: `The extra service hour from the reserved time will be charged at the price of 1 full day.`,
            },
        ],
        note: `Remarks: ** Other luxury cars refer to; Rolls-Royce, Bentley Flying Spurs, Bentley Bentayga, Benz Maybach S600, Benz Saloon S500E, and Audi Q7`,
        text_five_title: [
            {
                normal_text: `2.5 For multi-cities trip and/or overnight trip without accommodation provided for the driver, the company will collect an additional fee for the driver's accommodation at 500 Baht per night.`,
            },
            {
                note_text: `2.6 The maximum speed is 100 km per hour.`,
            },
            {
                normal_text: `2.7 30 minutes break every 4 hours' drive.`,
            },
            {
                normal_text: `2.8 Contrabands and pets are prohibited on the rented car.`,
            },
            {
                normal_text: `2.9 Inflammable and explosive items are prohibited on the rented car.`,
            },
            {
                normal_text: `2.10 No smoking, alcohol drinking, and/or the use of any substances on the rented car.`,
            },
            {
                normal_text: `2.11 Food or items with strong lasting odor are prohibited on the rented car.`,
            },
            {
                normal_text: `2.12 Please be mindful to not damage or lose any parts of the rented car. If there were any damages and/or lost on the rented car, the client will be fully responsible for the cost of any`,
            },
            {
                normal_text:
                    '2.13 damages and/or lost which will be paid upon the return of chauffeur service. The chauffeur service is for pick-up and travelling. Commercial uses or any act against the law are strictly prohibited.',
            },
            {
                normal_text: `2.14 The client, all passengers, and relevant personnel are NOT encouraged to post still or motive pictures of the car's interior and exterior or staffs on any social media channels unless agreed upon with the company.`,
            },
            {
                normal_text:
                    '2.15 The client is prohibited from renting out or providing service from the rented car provided by the company. The client is also prohibited from encouraging irrelevant person (s) to use the rented car. In the case where there are any damages and/or lost to the rented car, the client will be fully responsible costly and legally.',
            },
        ],
        title_text_three: `3. Non-Disclosure Agreement`,
        desc_text_three: `Any documents and/or information relating to this chauffeur service agreement where they are clearly stated to be confidential or non-disclosure of any forms will be regarded as utmost confidential. The client and/or associates will not disclose or publish unless: they are necessities for legal procedures as enforced by court or enforceable organization, and/or disclosing to related personnel or advisor to either party. All disclosure and publication must be agreed upon by both parties.`,
        title_text_four: `4. Transfer of the right`,
        desc_text_four:
            'The client hereby agrees to not transferring all rights and responsibility agreed in this agreement, fully or partially, to the third party, unless it has been assented from the company in writing. However, the company reserves the right to transfer the rights and duties in this agreement, fully or partially, to the third party without having a writing consent from the client.',
        title_text_five: '5. Cancellation/Refund',
        text_dot_five: [
            `5.1 The deposit at 50 (fifty) percent of the full price shall be made in advance. The remaining balance and additional fees (if any) shall be paid on the day chauffeur service is concluded. The deposit will be made on the day the reservation is confirmed via bank transfer or other payment gateways provided by the company.`,
            '5.2 After the reservation has been confirmed and the deposit has been paid, the company reserves all rights on the amount deposited in case of cancellation. Reschedule or amendment could be made no shorter that 24 hours prior. The company and the client will discuss the reschedule of the itinerary. For the reschedule notification that is made less than 24 hours prior the reserved date and time, the company reserves all rights from returning the deposit amount.',
            `5.3 For cancellation or decrease in service period, the company reserves the right to charge the service fee as originally reserved.`,
        ],
        title_text_six: '6. Termination of Service',
        text_dot_six: [
            '6.1 The company and the client both agree that every part of this agreement is essential. On the event where the client fails to complete the payment of service fee, deposit, any additional expenses occur, or misconduct against this agreement, fully or partially, the company reserves the right to terminate this agreement or suspend the service. The company is eligible to confiscate the deposit or any other payment that the client has made prior.',
            '6.2 In the event where the client fails to complete any obligated payment as stated in this agreement without termination notice from the company, the client is accountable for the fine of the full amount agreed upon at the rate of 15 (fifteen) percent per year starting from the due date until the full amount has been fully paid. The client is also accountable for following up on collection of overdue amounts at the rate of 4 (four) percent per year. However, this will not limit the right where the company can terminate the agreement or make any procedures according to the company policy as stated in this agreement.',
            `6.3 In the event where the company is accountable to pay the client in relation to this agreement or terms and conditions, the company will reduce the amount where the client accounts to pay the company.`,
            `6.4 Any actions made by the company to protect its right stated in this agreement, it will be regarded as fully assented by the client. The client agrees to never use this to hold the company accountable financially or legally.`,
        ],
        title_text_seven: '7. Others',
        text_dot_seven: [
            `7.1 In the event where it was later found that any clause in this agreement is not in accordance with the law, invalid, void, or unenforceable under applicable law, the remaining of this agreement shall remain in full force and effect.`,
            `7.2 The company can update or edit this terms and conditions of this agreement. If there is any changes or update to this agreement, the company will notify where the change has been made together with the timeframe when the updated version shall be enforced using appropriate means such as the company's website.`,
            `7.3 This agreement shall be governed and interpreted in accordance with Thai law.`,
        ],
        last_text: `The client has read and understood the message throughout and saw that it matched his intentions. The client now accepts and willing to comply with this agreement in all respects.`,
    },
    cn: {
        head_title: '司机服务合同',
        one_title: `1. 条款和条件`,
        one_dot_text:
            'Pinvestment Company Limited (“公司”）同意提供带司机的汽车租赁服务（“司机服务”），且“客户”同意接受司机服务，具体内容详见本合同（“协议”）“客户”特此同意本“协议”中的所有条款和条件。',
        two_title: `2. 服务条款`,
        two_one_title: `2.1	乘客人数根据公司提供的所选车厢的可用座位数而定。`,
        two_two_title: `2.2	司机服务费将根据所选车辆的性能而定。 服务费不包括：燃油费、通行费、停车费和/或与行程相关的任何其他费用。 司机服务中提供的所有车辆都将向客户提供满箱燃油。 行程结束后，乘客有义务在返回时加满油箱。 如果返回司机服务时未能加满油箱，公司保留向客户收取差额费用的权利。`,
        two_three_title: `2.3 白天服务时间为“10小时”。 服务时间不会超过服务当日的23.59。 00:00 之后所需的服务将被视为额外一天，客户有义务支付另一全天的服务费用。`,
        two_dot_text: `对于01:00-07:00期间的司机服务, 客户需要在服务日期前3天进行预订。`,
        two_four_title: `2.4 超出原定行程时间`,
        three_dot_text: `若司机服务时间延长，额外服务费如下：`,
        text_table: [
            {
                one_desc: `汽车模型`,
                two_desc: `服务时间`,
                three_desc: `时间超过原定时间`,
                four_desc: `每小时额外服务费`,
            },
            {
                one_desc: `丰田埃尔法 2024`,
                two_desc: `最大限度: 4个小时`,
                three_desc: `不晚于2小时`,
                four_desc: `1,000 泰铢`,
            },
            {
                one_desc: `丰田埃尔法 2024`,
                two_desc: `最大限度: 10个小时`,
                three_desc: `不晚于2小时`,
                four_desc: `500 泰铢`,
            },
            {
                one_desc: `**其他豪华车`,
                two_desc: `最大限度: 10个小时`,
                three_desc: `-`,
                four_desc: `预订时间以外的服务时间将按 1 天的价格收取。`,
            },
        ],
        note: `备注：**其他豪华车指的是； 劳斯莱斯、宾利飞刺、宾利添越、奔驰迈巴赫 S600、奔驰轿车 S500E、奥迪 Q7`,
        text_five_title: [
            {
                normal_text: `2.5 对于多城市行程和/或过夜行程且没有为司机提供住宿的情况，公司将收取每晚 500 泰铢的额外司机住宿费。`,
            },
            {
                note_text: `2.6 最高速度为每小时 100 公里。`,
            },
            {
                normal_text: `2.7 每行驶 4 小时休息 30 分钟。`,
            },
            {
                normal_text: `2.8 租赁车辆内禁止携带非法物品和宠物。`,
            },
            {
                normal_text: `2.9 租车时禁止携带易燃易爆物品。`,
            },
            {
                normal_text: `2.10 租车内禁止吸烟、饮酒和/或使用任何成瘾物质。`,
            },
            {
                normal_text: `2.11 禁止在租用的汽车上携带食物或具有强烈持久气味的物品。`,
            },
            {
                normal_text: `2.12 小心不要损坏或丢失租赁汽车的任何部件。 如果租赁车辆有任何损坏/丢失，客户将负责支付归还车辆时支付的所有损坏/丢失费用.`,
            },
            {
                normal_text:
                    '2.13 代驾服务主要是接机和出行。 严禁商业用途或任何非法活动。',
            },
            {
                normal_text: `2.14 除非与公司同意，否则不鼓励客户、所有乘客和相关人员在任何社交媒体渠道上发布汽车内外或员工的图片和视频。`,
            },
            {
                normal_text:
                    '2.15 客户不得租赁车辆或提供公司提供的服务。 还禁止客户怂恿无关人员使用租赁车辆。 如果租赁车辆出现任何损坏/丢失，客户将承担所有法律费用。',
            },
        ],
        title_text_three: `3. 保密协议`,
        desc_text_three: `与本司机服务协议相关的任何文件和/或信息，如果明确声明为机密或不以任何形式披露，将被视为最高机密。 客户和/或同事不会披露或发布，除非：它们是法院或可执行组织强制执行的法律程序所必需的，和/或向任何一方的相关人员或顾问披露。 所有披露和发布必须经双方同意。`,
        title_text_four: `4. 权利转让`,
        desc_text_four:
            '客户特此同意，除非得到本公司书面同意，否则不将本协议约定的所有权利和责任全部或部分转让给第三方。 但本公司保留在未经客户书面同意的情况下将本协议中的权利和义务全部或部分转让给第三方的权利。',
        title_text_five: '5. 取消/退款',
        text_dot_five: [
            `5.1 应提前支付全价50%的押金。 余额和附加费用（如有）应在司机服务结束当天支付。 押金将在预订确认当天通过银行转账或公司提供的其他支付方式支付。`,
            '5.2 预订确认并支付押金后，若取消预订，本公司保留对押金金额的所有权利。 重新安排或修改的时间不得短于 24 小时前。 公司将与客户协商重新安排行程。 对于在预订日期和时间前24小时内发出的改期通知, 本公司保留退回押金金额的所有权利。',
            `5.3 如取消或缩短服务期限，本公司保留按原保留收取服务费的权利。`,
        ],
        title_text_six: '6. 终止服务',
        text_dot_six: [
            '6.1 公司和客户均同意本协议的每一部分都是必不可少的。 若客户未能全部或部分完成服务费、押金、任何额外费用的支付，或有违反本协议的行为，本公司保留终止本协议或暂停服务的权利。 公司有资格没收客户之前支付的押金或任何其他付款。',
            '6.2 若是客户在未收到公司终止通知的情况下未能完成本协议中规定的任何义务的付款，则客户应承担从到期日起每年支付 15% 的罚款，直至付清约定的全部金额。 客户还按每年百分之四的比例负责后续程序费用。 然而，这并不限制公司终止本协议或根据本协议中所述的公司政策采取任何诉讼的权利。',
            `6.3 若是公司有责任根据本协议或条款和条件向客户付款，公司将减少客户账户向公司支付的金额。`,
            `6.4 本公司为维护本协议所述权利而采取的任何行动，将被视为客户的完全同意。 客户同意绝不用此来追究公司的财务或法律责任。`,
        ],
        title_text_seven: '7. 其他',
        text_dot_seven: [
            `7.1 若是后来发现本协议中的任何条款不符合法律、无效、无效或根据适用法律不可执行，则本协议的其余部分仍具有完全效力。`,
            `7.2 公司可以更新或编辑本协议的条款和条件。 如果本协议有任何变更或更新，公司将通过公司网站等适当方式通知变更地点以及更新版本的执行时间。`,
            `7.3 本协议应根据泰国法律进行管辖和解释。`,
        ],
        last_text: `客户已阅读并理解了整个消息，并发现它符合他的意图。 客户现在接受并愿意在所有方面遵守本协议。`,
    },
};

export const detailCar = {
    en: [
        {
            id: 1,
            name: 'Rolls Royce Phantom',
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration: 8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Drive',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
                '- Full Payment',
            ],
            price: [
                {
                    price: '250,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: rollsRoyceCar.src,
                    alt: 'Rolls Royce Phantom Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneRolls.src,
                    alt: 'Rolls Royce Phantom Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoRolls.src,
                    alt: 'Rolls Royce Phantom Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeRolls.src,
                    alt: 'Rolls Royce Phantom Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourRolls.src,
                    alt: 'Rolls Royce Phantom Options Four',
                },
            ],
            engine: {
                one: '3 Seats',
                two: '7,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 7,
            name: 'TOYOTA ALPHARD 2.5 Z Van 2WD 2024',
            detail: [
                '- Service Time Of 10 Hours.',
                '- Routes ONLY in Bangkok',
                '- Free Drive',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
            ],
            price: [
                {
                    price: '3,500฿',
                    value: 'part_day',
                },
                {
                    price: '8,500฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: alPhardCar.src,
                    alt: 'Toyota Alphard Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneAlPhard.src,
                    alt: 'Toyota Alphard Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoAlPhard.src,
                    alt: 'Toyota Alphard Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeAlPhard.src,
                    alt: 'Toyota Alphard Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourAlPhard.src,
                    alt: 'Toyota Alphard Options Four',
                },
                {
                    id: 6,
                    thumbnail: optionFiveAlPhard.src,
                    alt: 'Toyota Alphard Options Five',
                },
            ],
            engine: {
                one: '5 Seats',
                two: '2,500 cc.',
                three: 'Driver',
            },
        },
        {
            id: 2,
            name: 'Bentley Flying SPUR w 12',
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration: 8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Drive',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
                '- Full Payment',
            ],
            price: [
                {
                    price: '100,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: bentleyCar.src,
                    alt: 'Bentley Flying SPUR w 12 Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Four',
                },
                {
                    id: 6,
                    thumbnail: optionFiveBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Five',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 3,
            name: 'Mercedes Benz S600',
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration: 8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Drive',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
                '- Full Payment',
            ],
            price: [
                {
                    price: '50,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: maybachCar.src,
                    alt: 'Mercedes Benz S600 Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneMaybach.src,
                    alt: 'Mercedes Benz S600 Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoMaybach.src,
                    alt: 'Mercedes Benz S600 Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeMaybach.src,
                    alt: 'Mercedes Benz S600 Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourMaybach.src,
                    alt: 'Mercedes Benz S600 Options Four',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 4,
            name: 'Mercedes Benz S500E',
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration: 8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Drive',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
                '- Full Payment',
            ],
            price: [
                {
                    price: '40,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: benzseCar.src,
                    alt: 'Mercedes Benz S500E Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneBenzse.src,
                    alt: 'Mercedes Benz S500E Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoBenzse.src,
                    alt: 'Mercedes Benz S500E Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeBenzse.src,
                    alt: 'Mercedes Benz S500E Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourBenzse.src,
                    alt: 'Mercedes Benz S500E Options Four',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 5,
            name: 'Bentley Bentayga',
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration: 8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Drive',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
                '- Full Payment',
            ],
            price: [
                {
                    price: '50,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: bentaygaCar.src,
                    alt: 'Bentley Bentayga Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneBentayga.src,
                    alt: 'Bentley Bentayga Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoBentayga.src,
                    alt: 'Bentley Bentayga Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeBentayga.src,
                    alt: 'Bentley Bentayga Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourBentayga.src,
                    alt: 'Bentley Bentayga Options Four',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 6,
            name: 'Audi Q7 35TFSI',
            detail: [
                '- Service Time Of 10 Hours.',
                '- Duration: 8.00-06.00 PM.',
                '- Routes ONLY in Bangkok',
                '- Free Drive',
                '- Insurance Covers',
                '- Fuel policy: Full To Full',
                '- Full Payment',
            ],
            price: [
                {
                    price: '25,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: audiCar.src,
                    alt: 'Audi Q7 35TFSI Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneAudi.src,
                    alt: 'Audi Q7 35TFSI Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoAudi.src,
                    alt: 'Audi Q7 35TFSI Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeAudi.src,
                    alt: 'Audi Q7 35TFSI Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourAudi.src,
                    alt: 'Audi Q7 35TFSI Options Four',
                },
            ],
            engine: {
                one: '6 Seats',
                two: '3,000 cc.',
                three: 'Driver',
            },
        },
    ],
    th: [
        {
            id: 1,
            name: 'Rolls Royce Phantom',
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
                '- ชำระเต็มจำนวน',
            ],
            price: [
                {
                    price: '250,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: rollsRoyceCar.src,
                    alt: 'Rolls Royce Phantom Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneRolls.src,
                    alt: 'Rolls Royce Phantom Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoRolls.src,
                    alt: 'Rolls Royce Phantom Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeRolls.src,
                    alt: 'Rolls Royce Phantom Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourRolls.src,
                    alt: 'Rolls Royce Phantom Options Four',
                },
            ],
            engine: {
                one: '3 Seats',
                two: '7,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 7,
            name: 'TOYOTA ALPHARD 2.5 Z Van 2WD 2024',
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- เส้นทาง:(กรุงเทพฯ และต่างจังหวัด)',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
            ],
            price: [
                {
                    price: '3,500฿',
                    value: 'part_day',
                },
                {
                    price: '8,500฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: alPhardCar.src,
                    alt: 'Toyota Alphard Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneAlPhard.src,
                    alt: 'Toyota Alphard Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoAlPhard.src,
                    alt: 'Toyota Alphard Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeAlPhard.src,
                    alt: 'Toyota Alphard Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourAlPhard.src,
                    alt: 'Toyota Alphard Options Four',
                },
                {
                    id: 6,
                    thumbnail: optionFiveAlPhard.src,
                    alt: 'Toyota Alphard Options Five',
                },
            ],
            engine: {
                one: '5 Seats',
                two: '2,500 cc.',
                three: 'Driver',
            },
        },
        {
            id: 2,
            name: 'Bentley Flying SPUR w 12',
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
                '- ชำระเต็มจำนวน',
            ],
            price: [
                {
                    price: '100,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: bentleyCar.src,
                    alt: 'Bentley Flying SPUR w 12 Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Four',
                },
                {
                    id: 6,
                    thumbnail: optionFiveBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Five',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 3,
            name: 'Mercedes Benz S600',
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
                '- ชำระเต็มจำนวน',
            ],
            price: [
                {
                    price: '50,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: maybachCar.src,
                    alt: 'Mercedes Benz S600 Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneMaybach.src,
                    alt: 'Mercedes Benz S600 Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoMaybach.src,
                    alt: 'Mercedes Benz S600 Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeMaybach.src,
                    alt: 'Mercedes Benz S600 Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourMaybach.src,
                    alt: 'Mercedes Benz S600 Options Four',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 4,
            name: 'Mercedes Benz S500E',
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
                '- ชำระเต็มจำนวน',
            ],
            price: [
                {
                    price: '40,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: benzseCar.src,
                    alt: 'Mercedes Benz S500E Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneBenzse.src,
                    alt: 'Mercedes Benz S500E Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoBenzse.src,
                    alt: 'Mercedes Benz S500E Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeBenzse.src,
                    alt: 'Mercedes Benz S500E Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourBenzse.src,
                    alt: 'Mercedes Benz S500E Options Four',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 5,
            name: 'Bentley Bentayga',
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
                '- ชำระเต็มจำนวน',
            ],
            price: [
                {
                    price: '50,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: bentaygaCar.src,
                    alt: 'Bentley Bentayga Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneBentayga.src,
                    alt: 'Bentley Bentayga Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoBentayga.src,
                    alt: 'Bentley Bentayga Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeBentayga.src,
                    alt: 'Bentley Bentayga Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourBentayga.src,
                    alt: 'Bentley Bentayga Options Four',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 6,
            name: 'Audi Q7 35TFSI',
            detail: [
                '- ระยะเวลาการบริการ 10 ชั่วโมง',
                '- กำหนดเวลา 08.00- 18.00 น.',
                '- เฉพาะเส้นทางในกรุงเทพมหานคร',
                '- ค่าบริการรวมพนักงานขับ',
                '- ประกันภัยขั้นพื้นฐาน',
                '- น้ำมันเชื้อเพลิงรับ-คืนรถ: เต็มถัง',
                '- ชำระเต็มจำนวน',
            ],
            price: [
                {
                    price: '25,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: audiCar.src,
                    alt: 'Audi Q7 35TFSI Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneAudi.src,
                    alt: 'Audi Q7 35TFSI Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoAudi.src,
                    alt: 'Audi Q7 35TFSI Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeAudi.src,
                    alt: 'Audi Q7 35TFSI Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourAudi.src,
                    alt: 'Audi Q7 35TFSI Options Four',
                },
            ],
            engine: {
                one: '6 Seats',
                two: '3,000 cc.',
                three: 'Driver',
            },
        },
    ],
    cn: [
        {
            id: 1,
            name: 'Rolls Royce Phantom',
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策: 满箱送满箱回',
                '- 全额付款',
            ],
            price: [
                {
                    price: '250,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: rollsRoyceCar.src,
                    alt: 'Rolls Royce Phantom Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneRolls.src,
                    alt: 'Rolls Royce Phantom Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoRolls.src,
                    alt: 'Rolls Royce Phantom Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeRolls.src,
                    alt: 'Rolls Royce Phantom Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourRolls.src,
                    alt: 'Rolls Royce Phantom Options Four',
                },
            ],
            engine: {
                one: '3 Seats',
                two: '7,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 7,
            name: 'TOYOTA ALPHARD 2.5 Z Van 2WD 2024',
            detail: [
                '- 全天服务 (10小时)',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策: 满箱送满箱回',
            ],
            price: [
                {
                    price: '3,500฿',
                    value: 'part_day',
                },
                {
                    price: '8,500฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: alPhardCar.src,
                    alt: 'Toyota Alphard Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneAlPhard.src,
                    alt: 'Toyota Alphard Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoAlPhard.src,
                    alt: 'Toyota Alphard Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeAlPhard.src,
                    alt: 'Toyota Alphard Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourAlPhard.src,
                    alt: 'Toyota Alphard Options Four',
                },
                {
                    id: 6,
                    thumbnail: optionFiveAlPhard.src,
                    alt: 'Toyota Alphard Options Five',
                },
            ],
            engine: {
                one: '5 Seats',
                two: '2,500 cc.',
                three: 'Driver',
            },
        },
        {
            id: 2,
            name: 'Bentley Flying SPUR w 12',
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策: 满箱送满箱回',
                '- 全额付款',
            ],
            price: [
                {
                    price: '100,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: bentleyCar.src,
                    alt: 'Bentley Flying SPUR w 12 Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Four',
                },
                {
                    id: 6,
                    thumbnail: optionFiveBentley.src,
                    alt: 'Bentley Flying SPUR w 12 Options Five',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 3,
            name: 'Mercedes Benz S600',
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策: 满箱送满箱回',
                '- 全额付款',
            ],
            price: [
                {
                    price: '50,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: maybachCar.src,
                    alt: 'Mercedes Benz S600 Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneMaybach.src,
                    alt: 'Mercedes Benz S600 Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoMaybach.src,
                    alt: 'Mercedes Benz S600 Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeMaybach.src,
                    alt: 'Mercedes Benz S600 Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourMaybach.src,
                    alt: 'Mercedes Benz S600 Options Four',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 4,
            name: 'Mercedes Benz S500E',
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策: 满箱送满箱回',
                '- 全额付款',
            ],
            price: [
                {
                    price: '40,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: benzseCar.src,
                    alt: 'Mercedes Benz S500E Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneBenzse.src,
                    alt: 'Mercedes Benz S500E Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoBenzse.src,
                    alt: 'Mercedes Benz S500E Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeBenzse.src,
                    alt: 'Mercedes Benz S500E Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourBenzse.src,
                    alt: 'Mercedes Benz S500E Options Four',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 5,
            name: 'Bentley Bentayga',
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策: 满箱送满箱回',
                '- 全额付款',
            ],
            price: [
                {
                    price: '50,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: bentaygaCar.src,
                    alt: 'Bentley Bentayga Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneBentayga.src,
                    alt: 'Bentley Bentayga Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoBentayga.src,
                    alt: 'Bentley Bentayga Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeBentayga.src,
                    alt: 'Bentley Bentayga Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourBentayga.src,
                    alt: 'Bentley Bentayga Options Four',
                },
            ],
            engine: {
                one: '4 Seats',
                two: '6,000 cc.',
                three: 'Driver',
            },
        },
        {
            id: 6,
            name: 'Audi Q7 35TFSI',
            detail: [
                '- 全天服务 (10小时)',
                '- 服务时间: 08.00-18.00',
                '- 仅限曼谷地区',
                '- 服务费包含司机',
                '- 标准保险',
                '- 燃油政策: 满箱送满箱回',
                '- 全额付款',
            ],
            price: [
                {
                    price: '25,000฿',
                    value: 'full_day',
                },
            ],
            gallery: [
                {
                    id: 1,
                    thumbnail: audiCar.src,
                    alt: 'Audi Q7 35TFSI Car',
                },
                {
                    id: 2,
                    thumbnail: optionOneAudi.src,
                    alt: 'Audi Q7 35TFSI Options',
                },
                {
                    id: 3,
                    thumbnail: optionTwoAudi.src,
                    alt: 'Audi Q7 35TFSI Options Two',
                },
                {
                    id: 4,
                    thumbnail: optionThreeAudi.src,
                    alt: 'Audi Q7 35TFSI Options Three',
                },
                {
                    id: 5,
                    thumbnail: optionFourAudi.src,
                    alt: 'Audi Q7 35TFSI Options Four',
                },
            ],
            engine: {
                one: '6 Seats',
                two: '3,000 cc.',
                three: 'Driver',
            },
        },
    ],
};
